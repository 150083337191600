.get-in-touch-section {
  position: relative;
  z-index: 10;
  padding-top: ac(40px, 20px);
  padding-bottom: 0;

  &__color-bg {
    background: var(--cl-teal);
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding-top: ac(110px, 30px);
    padding-bottom: ac(59px, 40px);
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .get-in-touch-section__title {
      @media (min-width: 640px) {
        width: 48.79%;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    .get-in-touch-section__text {
      @media (min-width: 640px) {
        margin-top: 10px;
        width: 48.79%;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(37px, 30px);
    }

    @media (max-width: 639px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__title {
    h2 {
      font-size: ac(40px, 26px);
      font-weight: 600;
      line-height: 125%;
      font-family: var(--font-main);

      span {
        color: var(--cl-primrose) !important;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__text {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__subtitle {
    font-size: ac(34px, 22px);
    line-height: 1.18;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 16px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .meta-input {
        &:nth-child(3):last-child {
          grid-column: 1 / -1;
        }
      }
    }

    @mixin media 451 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-list {
    padding-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% - 180px);

    .meta-checkbox-list__item:not(:last-child) {
      margin-bottom: ac(11px, 8px);
    }

    .meta-checkbox__text a:hover {
      color: var(--cl-beige);
      text-decoration-color: var(--cl-beige);
    }

    @media (max-width: 639px) {
      width: 100%;
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    /*@media (max-width: 639px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 20px;
    }*/

    @media (min-width: 1025px) {
      .btn {
        height: 64px;
        min-width: 198px;

        .btn__text {
          font-size: 20px;
        }
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    grid-row-gap: 32px;
    grid-column-gap: ac(30px, 20px);
    flex-wrap: wrap;
  }

  &__item {
    width: calc(32.9% - ac(15px, 10px));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid var(--cl-black);
    padding-top: ac(19px, 28px);

    @mixin media 1100 {
      width: calc(32.6% - ac(15px, 10px));
    }

    @mixin media 851 {
      width: calc(50% - ac(15px, 10px));
    }

    @mixin media 551 {
      width: 100%;
    }
  }

  &__item-title {
    font-size: ac(34px, 22px);
    line-height: 117.65%;
    min-height: ac(80px, 52px);

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    @mixin media 1050 {
      font-size: ac(26px, 22px);
    }

    @media (max-width: 639px) {
      br {
        display: none;
      }
    }
  }

  &__item-text {
    width: calc(100% + 10px);
    padding-right: 10px;

    &:not(:last-child) {
      margin-bottom: ac(31px, 14px);
    }
  }

  &__item-links {
    margin-top: auto;
    .link-btn__text {
      font-size: ac(20px, 18px);
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
  }

  &.form-type {
    .get-in-touch-section {
      &__wrap {
        padding-top: ac(50px, 30px);
      }
    }
  }

  &.pb-type {
    padding-top: ac(80px, 40px);
  }
}
