.encouragement-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  z-index: 10;
  overflow: hidden;

  &__color-bg {
    background: var(--cl-beige);
    overflow: hidden;
  }

  &__wrap {
    padding-top: ac(51px, 30px);
    padding-bottom: ac(50px, 30px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__title {
    h2 {
      br {
        + span {
          margin-left: ac(54px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        width: 99%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        grid-row-gap: 30px;
        grid-column-gap: ac(40px, 20px);
        flex-wrap: wrap;
      }

      .slider-buttons {
        display: none;
      }

      .encouragement-section__item {
        width: calc(50% - ac(20px, 10px));

        @mixin media 1430 {
          width: calc(49.5% - ac(20px, 10px));
        }

        @mixin media 551 {
          width: 100%;
        }
      }
    }

    &.swiper-initialized {
      overflow: visible;

      .slider-buttons {
        .slider-btn {
          margin-top: 30px;
        }
      }

      @mixin media 551 {
        max-width: 350px;
        margin-left: 0;
      }
    }
  }

  &__item {
    border-left: 4px solid var(--cl-leaf);
    padding-left: ac(25px, 16px);
    padding-top: ac(20px, 16px);
    padding-bottom: ac(20px, 16px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-title {
    font-size: ac(34px, 22px);
    line-height: 1.18;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: ac(32px, 18px);
    }
  }

  &__item-text {
    padding-right: 7px;
    width: 100%;

    &[data-simplebar] {
      max-height: calc((ac(18px, 16px) * 1.55) * 8.1);

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-beige);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-mint);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }

      @mixin media 425 {
        max-height: calc((ac(18px, 16px) * 1.55) * 10.1);
      }
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}
