.group-card {
  border: 1px solid var(--cl-black);
  background: var(--cl-background);
  padding: ac(27px, 20px);
  min-height: ac(394px, 300px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  &__logo {
    @mixin aspect-ratio 195, 59;
    width: 195px;
    margin-right: auto;

    img {
      object-fit: contain;
      object-position: top left;
    }

    &.catalyst {
      @mixin aspect-ratio 194.59, 59;
      width: 194.59px;
    }

    &.pure {
      @mixin aspect-ratio 112.86, 41.57;
      width: 112.86px;
    }

    &.kernel {
      @mixin aspect-ratio 170.44, 41.57;
      width: 170.44px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__info {
    margin-top: auto;
    width: 80.88%;
    text-align: right;

    p {
      font-family: var(--font-main);
      font-size: ac(30px, 20px);
      font-weight: 400;
      line-height: 1.33;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
