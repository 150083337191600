.roles-section {
  position: relative;
  z-index: 10;
  padding-top: ac(52px, 20px);
  padding-bottom: ac(40px, 20px);

  &__color-bg {
    background: var(--cl-mint);
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: ac(50px, 40px);
    padding-bottom: ac(50px, 40px);
    grid-row-gap: ac(58px, 30px);
  }

  &__slider-buttons {
    .slider-btn {
      margin-bottom: 28px;
    }
  }

  &__slider {
    overflow: visible;

    &:not(.swiper-initialized) {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: ac(0px, 4px);
    }

    @mixin media 390 {
      max-width: 320px;
      margin-left: 0;
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (min-width: 640px) {
    .roles-section {
      &__wrap {
        align-items: center;
      }

      &__title {
        order: 1;
        width: calc(100% - 220px);
        margin-top: ac(-10px, -0px);
      }

      &__buttons {
        margin-top: ac(30px, 0px);
        order: 2;
      }

      &__slider {
        order: 3;
      }
    }
  }

  &.sector-page {
    padding-top: ac(40px, 20px);

    .roles-section {
      &__buttons {
        @media (min-width: 640px) {
          margin-top: 18px;
        }
      }

      &__title {
        @media (min-width: 640px) {
          margin-top: 0;
        }
      }

      &__wrap {
        grid-row-gap: ac(50px, 30px);
      }
    }
  }

  &.job-details-page {
    padding-top: ac(40px, 20px);

    .roles-section {
      &__color-bg {
        background-color: var(--cl-leaf);
      }

      &__buttons {
        @media (min-width: 640px) {
          margin-top: 18px;
        }
      }

      &__title {
        @media (min-width: 640px) {
          margin-top: 0;
        }
      }

      &__wrap {
        grid-row-gap: ac(50px, 30px);
      }
    }

    .job-card {
      background-color: var(--cl-leaf);
    }

    @media (max-width: 639px) {
      padding-top: 20px;
    }
  }

  &.team-member-page {
    padding-top: ac(40px, 20px);

    @media (max-width: 639px) {
      padding-top: 0;
    }
  }
}
