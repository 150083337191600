.recruit-section {
  padding-top: ac(42px, 20px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;

  &__color-bg {
    background: var(--cl-beige);
  }

  &__wrap {
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 30px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title {
    width: 100%;
    max-width: ac(818px, 600px);

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    h2 {
      @media (max-width: 360px) {
        font-size: 30px;
      }
    }
  }

  &__text {
    width: 100%;
    max-width: ac(818px, 600px);

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__slider-buttons {
    @mixin min-media 951 {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__list {
    width: 40.26%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin media 951 {
      display: none;
    }

    li {
      width: 100%;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-top: 1px solid var(--cl-black);
      padding-top: 11px;

      span {
        font-size: ac(25px, 20px);
        line-height: 1.4;
        transition: all 0.3s ease;
        padding-left: 0;
        font-weight: 400;
        color: var(--cl-black);
        font-family: var(--font-main);
        transform-origin: left;
        max-width: calc(100% - 30px);
      }

      i {
        margin-top: ac(5px, 4px);
        font-size: 17px;
        color: var(--cl-black);
        transition: opacity 0.3s ease, color 0.3s ease;
        line-height: 20px;

        position: relative;

        &:after {
          bottom: -4px;
          left: 0;
          right: 0;
          height: 1px;
          background: var(--cl-black);
          position: absolute;
          content: '';
          transition: background-color 0.3s ease;
        }
      }

      &:not(:last-child) {
        padding-bottom: ac(17px, 15px);
      }

      &.active {
        span {
          color: var(--cl-leaf);
          transform: scale(1.2);
          font-weight: 500;
        }

        i {
          opacity: 0;
        }
      }

      &:not(.active) {
        &:hover {
          span {
            color: var(--cl-mint);
          }

          i {
            color: var(--cl-mint);

            &:after {
              background-color: var(--cl-mint);
            }
          }
        }
      }
    }
  }

  &__slider {
    width: 48.79%;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
    margin-top: ac(20px, 0px);

    @mixin media 951 {
      width: 100%;
      max-width: 600px;
    }
  }

  &__item {
    background: var(--cl-leaf);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(40px, 24px) ac(40px, 20px);

    &:not(.swiper-slide-active) {
      @mixin min-media 951 {
        opacity: 0 !important;
      }
    }
  }

  &__item-title {
    font-family: var(--font-second);
    font-size: ac(30px, 22px);
    line-height: 133.33%;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: ac(25px, 16px);
    }
  }

  &__item-text {
    &.small-size {
      p {
        font-size: 16px;
        line-height: ac(26px, 24px);

        @media (min-width: 1280px) {
          letter-spacing: -0.16px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }

    &[data-simplebar] {
      max-height: calc((ac(24px, 22px) * 1.42) * 8.1);
      width: calc(100% + 10px);
      padding-right: 10px;

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-background);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-mint);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 44px;
  }
}
