.testimonials-section {
  position: relative;
  z-index: 10;
  padding-top: ac(30px, 54px);
  padding-bottom: ac(40px, 30px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column-gap: ac(30px, 24px);

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: ac(288px, 200px);

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__title {
    width: 100%;

    h2 {
      font-size: ac(40px, 32px);
      font-weight: 600;
      line-height: ac(50px, 40px);
    }

    &:not(:last-child) {
      margin-bottom: ac(29px, 22px);
    }
  }

  &__slider {
    margin-top: ac(11px, -5px);
    max-width: ac(447px, 400px);
    overflow: visible;
    margin-left: 0;
    clip-path: inset(-20px calc((100% + 50px) * -1) -20px -10px);

    &:not(.swiper-initialized) {
      display: none;
    }

    @mixin media 390 {
      max-width: 305px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding-left: ac(44px, 38px);
    border-top: 1px solid var(--cl-leaf);
    padding-top: ac(19px, 15px);
    height: auto;
    cursor: default;
  }

  &__item-icon {
    color: var(--cl-mint);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ac(20px, 16px);
    left: 0;
    font-size: 22px;
  }

  &__item-text {
    width: 100%;

    &[data-simplebar] {
      max-height: calc((ac(20px, 16px) * 1.5) * 12.1);
      width: calc(100% + 10px);
      padding-right: 10px;

      .simplebar-track.simplebar-vertical {
        width: ac(4px, 3px);
        background: var(--cl-beige);
        border-radius: 0;
        .simplebar-scrollbar {
          background: var(--cl-leaf);
          border-radius: 0;
          &:before {
            content: none;
          }
        }
      }
    }

    &.small-size-second {
      &[data-simplebar] {
        max-height: calc((ac(18px, 16px) * 1.55) * 12.1);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(15px, 16px);
    }
  }

  &__item-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column-gap: ac(15px, 10px);
    grid-row-gap: ac(10px, 2px);
    /*margin-top: auto;*/

    @mixin min-media 1300 {
      min-height: 50px;
    }

    @mixin media 1300 {
      flex-wrap: wrap;
    }

    @media (max-width: 1025px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    /*@media (max-width: 639px) {
      padding-left: 6px;
    }*/
  }

  &__item-author,
  &__item-info {
    font-size: ac(15px, 14px);
    font-family: var(--font-main);
    line-height: 25px;
    font-weight: 500;
  }

  &__item-author {
    font-weight: 700;

    @mixin min-media 1300 {
      white-space: nowrap;
    }
  }

  &.sectors-page {
    .testimonials-section {
      &__color-bg {
        background: var(--cl-mint);
        overflow: hidden;
      }

      &__wrap {
        padding-top: ac(50px, 30px);
        padding-bottom: ac(50px, 30px);
      }

      &__item-icon {
        color: var(--cl-beige);
      }

      &__item {
        border-color: var(--cl-beige);
        transition: opacity 0.2s ease;

        &.swiper-slide-next {
          + .testimonials-section__item {
            opacity: 0;

            + .testimonials-section__item {
              opacity: 0;

              + .testimonials-section__item {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  &.pure-page {
    .testimonials-section {
      &__color-bg {
        background: var(--cl-royal);
      }
    }
  }

  &.catalyst-page {
    .testimonials-section {
      &__color-bg {
        background: var(--cl-tangerine);
      }
    }
  }

  &.careers-page {
    padding-top: ac(40px, 20px);

    .testimonials-section {

      &__color-bg {
        background: var(--cl-beige);
        overflow: hidden;
      }

      &__wrap {
        padding-top: ac(50px, 30px);
        padding-bottom: ac(50px, 30px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__title-container {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: ac(17px, 18px);
        }
      }

      &__title {
        width: 100%;

        h2 {
          font-size: ac(70px, 38px);
          font-weight: 600;
          line-height: 1.11;
          font-family: var(--font-main);

          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(25px, 20px);
        }
      }

      &__slider {
        width: 100%;
        max-width: 100%;
      }

      &__item-icon {
        color: var(--cl-leaf);
      }
    }

    &.careers-clients-page {
      .testimonials-section {

        &__color-bg {
          background: var(--cl-leaf);
        }

        &__item-icon {
          color: var(--cl-beige);
        }

        &__item {
          border-color: var(--cl-beige);
          transition: opacity 0.2s ease;
        }
      }
    }

    + .text-block-section.image-bottom-type.our-purpose-page {
      padding-top: ac(40px, 20px);

      @media (max-width: 639px) {
        padding-top: 0;
      }
    }

    @media (max-width: 639px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @media (max-width: 425px) {
      .testimonials-section {
        &__title {
          h2 {
            br {
              display: none;

              + span {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  &.classroom-page {

    .testimonials-section {

      &__wrap {
        padding-bottom: 0;
      }

      &__title {
        width: auto;
        margin-bottom: 0 !important;

        h2 {
          font-size: ac(60px, 38px);
          font-weight: 600;
          line-height: 1.11;
          font-family: var(--font-third) !important;

          .word {
            &:first-child {
              &:before {
                display: none !important;
              }

              margin-left: 0 !important;
            }
          }
        }
      }

      &__title-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        grid-column-gap: 20px;
        grid-row-gap: 24px;
        flex-wrap: wrap;
        width: 100%;
        
        &:not(:last-child) {
          margin-bottom: ac(42px, 30px);
        }

        @media (max-width: 451px) {
          flex-direction: column;
          grid-row-gap: 20px;
          justify-content: flex-start;
        }
      }

      &__slider-buttons {
        margin-top: auto;

        .slider-btn {
          &.slider-btn-dark {
            color: var(--cl-black);

            &:hover {
              &:not(.swiper-button-disabled) {
                color: #CBE2B8;
              }
            }
          }
        }
      }

      &__slider {
        overflow: hidden;
      }

      &__item {
        border-top: none;
        background: var(--cl-white);
        padding-top: ac(27px, 24px);
        padding-bottom: ac(27px, 24px);
        padding-right: ac(27px, 20px);
        padding-left: ac(48px, 30px);
        height: auto;
        position: relative;

        &:before {
          position: absolute;
          left: ac(27px, 15px);
          background: #CBE2B8;
          width: ac(4px, 3px);
          top: ac(27px, 24px);
          bottom: ac(27px, 24px);
          content: "";
          z-index: 1;
        }
      }

      &__item-text {
        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }
      }

      &__item-bottom {
        margin-top: auto;
      }

      &__item-info {
        font-weight: 500;
        font-size: ac(24px, 22px);
        line-height: 1.42;
        font-family: var(--font-third) !important;
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 20px;
    }
  }
}
