.become-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  overflow: hidden;
  z-index: 20;

  &__color-bg {
    background: var(--cl-clementine);
    position: relative;
  }

  &__decor-bg {
    position: absolute;
    z-index: 1;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right;
    }

    @media (max-width: 590px) {
      opacity: .3;
    }
  }

  &__wrap {
    padding-top: ac(110px, 40px);
    padding-bottom: ac(110px, 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
  }

  &__title {
    width: 100%;
    max-width: ac(606px, 400px);

    h2 {
      font-size: ac(80px, 38px);
      font-weight: 600;
      line-height: 112.50%;
      font-family: var(--font-main);

      .word:first-child::before {
        margin-left: ac(-7px, 0px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

  }

  &.about-page {
    .become-section {
      &__color-bg {
        background-color: var(--cl-teal);
      }

      &__title {
        max-width: ac(820px, 400px);
      }
    }

    @media (max-width: 639px) {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }

  &.classroom-page {
    .become-section {
      &__color-bg {
        background-color: #EC632B;
      }

      &__title {
        max-width: ac(960px, 450px);

        h2 {
          font-family: var(--font-third) !important;
          color: var(--cl-white) !important;
        }
      }

      &__buttons {
        .btn {
          &.btn-second {
            background-color: #CBE2B8;
            border-color: #CBE2B8;

            .btn__text {
              color: var(--cl-black);
            }

            &:hover {
              border-color: var(--cl-beige);
              background-color: var(--cl-beige);
            }
          }
        }
      }
    }

    @media (max-width: 639px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.classroom-page-second {
    .become-section {
      &__color-bg {
        background-color: #058668;
      }

      &__decor-bg {
        top: auto;
        height: 100%;
        max-height: ac(177px, 90px);
      }

      &__title {
        max-width: 100%;

        h2 {
          font-family: var(--font-third) !important;
          color: #CBE2B8 !important;
        }
      }

      &__buttons {
        .btn {
          &.btn-second {
            background-color: #CBE2B8;
            border-color: #CBE2B8;

            .btn__text {
              color: var(--cl-black);
            }

            &:hover {
              border-color: var(--cl-beige);
              background-color: var(--cl-beige);
            }
          }
        }
      }
    }

    @media (max-width: 639px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }


  @media (max-width: 639px) {
    padding-bottom: 0;
  }
}