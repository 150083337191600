.text-image-section {
  position: relative;
  z-index: 10;
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);

  &__color-bg {
    background: var(--cl-beige);
    position: relative;
  }

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image {
    @mixin aspect-ratio 500, 572;
    width: 40.26%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.33%;
  }

  &__title {
    width: 100%;
    max-width: ac(450px, 340px);

    &:not(:last-child) {
      margin-bottom: ac(35px, 24px);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(50px, 28px);
    }
  }

  @media (max-width: 639px) {
    .text-image-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &__content {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: ac(50px, 40px);
        }
      }

      &__image {
        width: 100%;
        max-width: 450px;
      }
    }
  }

  &.home-page {
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    /*margin-top: ac(40px, 20px);*/
    margin-bottom: ac(40px, 20px);

    /*border-top: 1px solid rgba(0, 52, 35, 0.1);*/
    border-bottom: 1px solid rgba(0, 52, 35, 0.1);

    .text-image-section {

      &__wrap {
        align-items: flex-start;
      }

      &__title {
        width: 100%;
        max-width: 100%;
        padding-top: ac(56px, 30px);
        padding-bottom: ac(50px, 30px);

        &:before {
          position: absolute;
          bottom: 0;
          right: ac(-47px, -30px);
          left: -100vw;
          height: 1px;
          background: rgba(0, 52, 35, 0.1);
          content: "";
        }

        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }
      }

      &__content {
        padding-bottom: ac(50px, 30px);

        @media (min-width: 769px) {
          padding-right: ac(40px, 30px);
          width: 52.58%;
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }
      }

      &__image {
        @mixin aspect-ratio 636, 607;

        @media (min-width: 1400px) {
          min-width: 636px;
          margin-right: -47px;
        }

        @media (min-width: 769px) {
          width: 51.21%;
        }
      }
    }

    @media (max-width: 768px) {
      .text-image-section {
        &__wrap {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__title {
          &:after {
            right: -100vw;
          }
        }

        &__content {
          width: 100%;
          padding-right: 0;
          padding-bottom: 30px;
          position: relative;

          &:not(:last-child) {
            margin-bottom: 0;
          }

          &:before {
            position: absolute;
            bottom: 0;
            right: -100vw;
            left: -100vw;
            height: 1px;
            background: rgba(0, 52, 35, 0.1);
            content: "";
          }
        }

        &__image {
          width: 100%;
          max-width: 450px;
        }
      }
    }
  }

  &.classroom-page {
    overflow: hidden;
    padding-top: ac(90px, 30px);
    padding-bottom: 0;
    margin-top: ac(40px, 20px);
    margin-bottom: ac(40px, 20px);

    /*border-top: 1px solid rgba(0, 52, 35, 0.1);*/
    border-bottom: 1px solid rgba(0, 52, 35, 0.1);

    .text-image-section {

      &__wrap {
        align-items: flex-start;
      }

      &__title {
        padding-top: 0;
        width: 100%;
        max-width: 100%;
        padding-bottom: 0;

        &:before, &:after {
          content: none;
        }

        h2 {
          font-family: var(--font-third) !important;

          .word {
            &:first-child {
              &:before {
                display: none !important;
              }

              margin-left: 0 !important;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(33px, 24px);
        }
      }

      &__content {
        padding-right: 20px;
        padding-bottom: ac(50px, 30px);

        @media (min-width: 1400px) {
          padding-right: 0;
        }

        @media (min-width: 769px) {
          width: 57.33%;
        }
      }

      &__text {
        &.big-size-second {
          font-weight: 400;
          font-size: ac(30px, 24px);
          line-height: 133.33%;
          color: #EC632B;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          blockquote,
          li {
            color: #EC632B;
          }

          p, li {
            font-weight: 400;
            font-size: ac(30px, 24px);
            line-height: 133.33%;
          }
        }

        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }

        + .text-image-section__text {
          padding-top: ac(50px, 30px);
          position: relative;

          &:after {
            position: absolute;
            top: 0;
            right: ac(-100px, -50px);
            left: -100vw;
            height: 1px;
            background: rgba(0, 52, 35, 0.1);
            content: "";
          }
        }
      }

      &__image {
        @mixin aspect-ratio 527, 834;

        @media (min-width: 1400px) {
          min-width: 527px;
          margin-right: -47px;
        }

        @media (min-width: 769px) {
          width: 42.43%;
        }
      }
    }

    @media (max-width: 768px) {
      .text-image-section {
        &__wrap {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__title {
          &:after {
            right: -100vw;
          }
        }

        &__content {
          width: 100%;
          padding-right: 0;
          padding-bottom: 30px;
          position: relative;

          &:not(:last-child) {
            margin-bottom: 0;
          }

          &:before {
            position: absolute;
            bottom: 0;
            right: -100vw;
            left: -100vw;
            height: 1px;
            background: rgba(0, 52, 35, 0.1);
            content: "";
          }
        }

        &__image {
          width: 100%;
          max-width: 450px;
        }
      }
    }
  }

  &.about-page {
    .text-image-section {
      &__title {
        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }
    }
  }

  &.bg-image-type {
    .text-image-section {
      &__color-bg {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 92.74%;
          z-index: 2;
          content: '';
          background: linear-gradient(
            270deg,
            rgba(28, 28, 28, 0.64) 0%,
            rgba(28, 28, 28, 0) 100%
          );
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: overlay;
          opacity: 0.25;
        }
      }

      &__bg {
        &:before {
          position: absolute;
          inset: 0;
          z-index: 2;
          content: '';
          background: var(--cl-beige);
          mix-blend-mode: soft-light;
          opacity: 0.5;

          @media (max-width: 639px) {
            opacity: .1;
          }
        }

        &:after {
          position: absolute;
          inset: 0;
          z-index: 1;
          content: '';
          background: var(--cl-mint);
          mix-blend-mode: multiply;
          opacity: 0.5;
        }
      }

      &__wrap {
        position: relative;
        z-index: 10;
        padding-top: ac(50px, 40px);
        padding-bottom: ac(50px, 40px);
      }

      &__content {
        width: 48.79%;

        @media (max-width: 639px) {
          width: 100%;
        }
      }

      &__title {
        max-width: 100%;
      }
    }

    @media (max-width: 767px) {
      .text-image-section {
        &__wrap {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        &__content {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: ac(50px, 40px);
          }
        }
      }
    }

    &.diversity-page {
      .text-image-section {
        &__color-bg {
          &:before {
            content: none;
          }
        }

        &__bg {
          @media (max-width: 639px) {
            img {
              object-position: 80% 50%;
            }
          }

          @mixin min-media 1800 {
            img {
              object-position: top;
            }
          }
        }

        &__wrap {
          min-height: ac(663px, 500px);
          justify-content: flex-start;
          align-items: flex-start;
        }

        &__content {
          background: var(--cl-beige);
          padding-top: ac(50px, 24px);
          padding-bottom: ac(50px, 24px);
          padding-left: ac(47px, 20px);
          padding-right: ac(47px, 20px);
          width: auto;
          max-width: ac(720px, 400px);

          @mixin media 451 {
            margin-top: auto;
            width: 100%;
          }
        }

        &__title {
          width: calc(100% + ac(2px, 0px));

          h2 {
            br {
              + span {
                margin-left: ac(54px, 24px);

                @mixin media 425 {
                  margin-left: 0;
                }
              }
            }

            @mixin media 425 {
              font-size: 32px;
            }

            @mixin media 374 {
              font-size: 30px;
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(52px, 30px);
          }
        }
      }

      @media (max-width: 639px) {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.careers-page {
        .text-image-section {
          &__color-bg {
            &:before {
              content: none;
            }

            &:after {
              content: none;
            }
          }

          &__bg {
            &:after {
              content: none;
            }
          }

          &__content {
            background: var(--cl-black);

            @media (max-width: 639px) {
              margin-top: auto;
            }
          }

          &__title h2 br + span {
            margin-left: ac(28px, 20px);
          }

          &__wrap {
            padding-top: ac(47px, 40px);

            @media (max-width: 639px) {
              min-height: 720px;
            }

          }
        }

      }
    }

    &.sector-page {
      .text-image-section {
        &__color-bg {
          &:before {
            content: none;
          }
        }

        &__wrap {
          min-height: ac(663px, 500px);
          justify-content: flex-start;
          align-items: flex-end;

          @media (max-width: 767px) {
            align-items: flex-start;
            justify-content: flex-end;
          }
        }

        &__content {
          background: var(--cl-black);
          padding-top: ac(50px, 24px);
          padding-bottom: ac(50px, 24px);
          padding-left: ac(47px, 20px);
          padding-right: ac(47px, 20px);
          width: 100%;
          max-width: ac(712px, 450px);
        }

        &__title {
          width: calc(100% + ac(2px, 0px));

          h2 {
            br {
              + span {
                margin-left: ac(54px, 24px);

                @mixin media 425 {
                  margin-left: 0;
                }
              }
            }

            @mixin media 374 {
              font-size: 30px;
            }
          }

          &:not(:last-child) {
            margin-bottom: ac(52px, 30px);
          }
        }

        &__buttons {
          margin-bottom: -2px;
          @mixin full-btn;
        }
      }

      @media (max-width: 639px) {
        padding-top: 0;
      }
    }
  }
}
