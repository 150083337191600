.first-section {
  margin-top: ac(52px, 40px);
  position: relative;
  z-index: 1;

  &__color-bg {
    position: relative;
    overflow: hidden;

    &:after {
      position: absolute;
      inset: 0;
      z-index: -2;
      background: var(--cl-teal);
      content: '';
    }
  }

  &__page-decor {
    @mixin aspect-ratio 1440, 1365;
    position: absolute;
    left: 0;
    width: 100%;
    /*top: ac(-52px, -40px);*/
    top: ac(-102px, -80px);
    z-index: -1;

    @media (max-width: 639px) {
      width: 1440px;
      margin-left: -530px;
      top: -110px;
    }

    @mixin min-media 1600 {
      top: -200px;
    }

    @mixin min-media 1800 {
      top: -400px;
    }
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      position: absolute;
      z-index: 1;
      inset: 0;
      content: '';
      background: linear-gradient(
        180deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.8) 0.01%,
        rgba(28, 28, 28, 0.6) 48.5%,
        rgba(28, 28, 28, 0.8) 100%
      );
      opacity: 0.6;
    }
  }

  &__wrap {
    min-height: 88.85vh;
    padding-top: 150px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @mixin media 1025 {
      @media (min-width: 640px) {
        min-height: 500px;
      }
    }

    @mixin media 551 {
      justify-content: flex-end;
      padding-bottom: 40px;
      min-height: 600px;
    }
  }

  &__title {
    width: 100%;
    max-width: 100%;
    text-align: center;

    h1 {
      color: var(--cl-beige);
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 24px);
    }
  }

  &__form {
    width: 100%;
    max-width: ac(642px, 440px);
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row-gap: 8px;

    .btn {
      min-width: 160px;
      z-index: 2;

      &:active {
        opacity: 0.8;
      }

      &__text {
        white-space: nowrap;
      }
    }

    @mixin media 551 {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__search-input {
    width: 100%;

    input {
      font-size: 17px;
      padding: 20px;
      background: var(--cl-beige);
      border: 1px solid var(--cl-mint);
      font-family: var(--font-second);
      height: ac(64px, 56px);
      font-weight: 400;
    }
  }

  @media (max-width: 639px) {
    margin-top: 0;
  }

  &.home-page {
    .first-section {
      &__title {
        h1 {
          .title-line {
            span {
              --line-index: 1 !important;
            }
          }
        }
      }

      &__bg {
        img {
          @media (max-width: 639px) {
            object-position: 20% 50%;
          }
        }
      }

      &__page-decor {
        @mixin aspect-ratio 748, 562.2;
        position: absolute;
        left: auto;
        right: ac(-164px, -80px);
        width: 100%;
        max-width: ac(748, 400px);
        bottom: ac(-354px, -200px);
        /*top: ac(-52px, -40px);*/
        top: auto;
        z-index: -1;

        @media (max-width: 767px) {
          opacity: .5;
        }

        @media (max-width: 639px) {
          width: 100%;
          margin-left: 0;
          top: auto;
        }

        @mixin min-media 1600 {
          top: auto;
        }

        @mixin min-media 1800 {
          top: auto;
        }
      }

      &__wrap {
        @mixin media 551 {
          justify-content: center;
        }
      }
    }
  }

  &.full-internal-type {
    .first-section {

      &__image-bg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: ac(39px, 0px);
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;

          @media (max-width: 640px) {
            object-position: 75% 50%;
          }
        }

        &:after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: #000;
          opacity: 0.3;
          z-index: 2;
        }
      }

      &__decor-bg {
        @mixin aspect-ratio 636, 825;
        position: absolute;
        z-index: 2;
        width: 80%;
        max-width: ac(636px, 400px);
        bottom: ac(-128px, -40px);
        right: 0;
      }

      &__decor-container {
        aspect-ratio: 631 / 688;
        position: absolute;
        z-index: 2;
        width: 80%;
        max-width: ac(631px, 400px);
        bottom: 0;
        right: 0;

        @media (max-width: 1400px) {
          max-width: ac(531px, 400px);
        }

        @media (max-width: 1100px) {
          max-width: ac(451px, 300px);
        }

        @media (max-width: 730px) {
          opacity: .5;
        }
      }

      &__decor-image {
        @mixin aspect-ratio 473, 546;
        width: 74.96%;
        position: absolute;
        left: 4.75%;
        top: 2.18%;
        z-index: 2;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);

        @media (max-width: 730px) {
          display: none;
        }
      }

      &__decor-brand {
        position: absolute;
        inset: 0;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__wrap {
        padding-right: 42%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 178px;
        padding-bottom: 56px;
        position: relative;
        z-index: 3;

        @media (max-width: 1400px) {
          padding-right: 47%;
        }

        @mixin media 1200 {
          padding-right: 45%;
        }


        @media (max-width: 639px) {
          padding-right: 0;
          justify-content: flex-end;
          padding-bottom: 40px;
          min-height: 600px;
        }

        @mixin min-media-height 1100 {
          min-height: 800px;
        }

        @mixin media 1025 {
          @media (min-width: 640px) {
            min-height: 600px;
          }
        }
      }

      &__brand-logo {
        width: auto;
        max-width: 80%;
        
        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }
      }



      &__title {
        text-align: left;
      }

      &__content {
        margin-top: auto;
        width: 100%;
        max-width: 606px;
        position: relative;
        z-index: 5;
      }

      &__text {
        &.big-size-second {
          margin-bottom: ac(-7px, 0px);
          font-weight: 500;
          p {
            font-weight: 500;
          }
        }
      }
    }

    &.pure-page {
      .first-section__decor-image {
        clip-path: none !important;
        @mixin aspect-ratio 411, 405;
        width: 65.13%;
        left: 1.11%;
        top: 5.96%;
      }
    }

    &.catalyst-page {
      .first-section__decor-image {
        clip-path: inset(0 0 0 0 round 50%) !important;
        border-radius: 50%;
        overflow: hidden;
        @mixin aspect-ratio 1, 1;
        width: 58.83%;
        left: 4.04%;
        top: 4.09%;
      }

      .first-section__decor-container {
        aspect-ratio: 668 / 684;

        @media (min-width: 1430px) {
          max-width: ac(668px, 400px);
        }
      }
    }

    &.contact-page {
      .first-section {

        &__image-bg {
          img {
            object-fit: cover;
            object-position: center;
          }
        }

        &__wrap {
          height: auto;
          min-height: auto;
          padding-right: ac(248px, 0px);
          padding-bottom: ac(85px, 56px);
        }

        &__content {
          max-width: 100%;
        }

        &__title {
          &:not(:last-child) {
            margin-bottom: ac(40px, 30px);
          }
        }
      }
    }
  }

  &.internal-type {
    .first-section {
      &__wrap {
        padding-right: 42%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 178px;
        padding-bottom: 56px;

        @mixin media 1200 {
          padding-right: 45%;
        }

        @media (max-width: 639px) {
          padding-right: 0;
          justify-content: flex-end;
          padding-bottom: 40px;
          min-height: 600px;
        }

        @mixin min-media-height 1100 {
          min-height: 800px;
        }

        @mixin media 1025 {
          @media (min-width: 640px) {
            min-height: 600px;
          }
        }
      }

      &__page-decor-container {
        @mixin aspect-ratio 1440, 1504;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        z-index: -1;
      }

      &__page-decor {
        opacity: 0.2 !important;
        @mixin aspect-ratio 2170, 1504;
        position: absolute;
        left: -162px;
        width: 150.69%;
        max-width: 2170px;
        /*top: ac(-52px, -40px);*/
        top: ac(-197px, -80px);
        z-index: -1;

        @media (max-width: 639px) {
          width: 1440px;
          margin-left: -530px;
          top: -110px;
        }

        @mixin min-media 1600 {
          top: -200px;
        }

        @mixin min-media 1800 {
          top: -400px;
        }
      }

      &__image {
        position: absolute;
        top: ac(39px, 0px);
        right: 0;
        bottom: 0;
        width: 40.94%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }

        &:after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          top: 0;
          height: 37.24%;
          background: linear-gradient(
            180deg,
            #1c1c1c 0%,
            rgba(28, 28, 28, 0.8) 0.01%,
            rgba(28, 28, 28, 0.51) 48.5%,
            rgba(28, 28, 28, 0) 100%
          );
          opacity: 0.7;
          z-index: 2;
        }

        @media (max-width: 639px) {
          width: 100%;

          &:after {
            background: linear-gradient(
              180deg,
              #1c1c1c 0%,
              rgba(28, 28, 28, 0.8) 0.01%,
              rgba(28, 28, 28, 0.6) 48.5%,
              rgba(28, 28, 28, 0.8) 100%
            );
            opacity: 0.8;
            height: 100%;
          }
        }
      }

      &__title {
        text-align: left;
      }

      &__content {
        margin-top: auto;
        width: 100%;
        max-width: 606px;
        position: relative;
        z-index: 5;
      }
    }

    &.diversity-page {
      .first-section {
        &__text {
          @media (min-width: 1280px) {
            padding-right: 10px;
          }
        }
      }
    }

    &.classroom-page {
      .first-section {

        &__page-decor {
          z-index: -2;
          opacity: .02 !important;
        }

        &__color-bg {
          &:after {
            background-color: #058668;
          }
        }

        &__wrap {
          @media (min-width: 1280px) {
            padding-right: 28%;
          }
        }

        &__title {
          h1 {
            font-family: var(--font-third) !important;
            color: #CBE2B8;
            letter-spacing: -0.01em;
          }
        }

        &__logo-classroom {
          @mixin aspect-ratio 365, 72;
          width: 100%;
          max-width: 365px;
          margin-bottom: ac(19px, 10px);

          img {
            width: 100%;
            object-fit: contain;
            object-position: left;
          }
        }
      }
    }

    &.team-page {
      .first-section {
        &__content {
          max-width: 100%;
          padding-left: ac(212px, 80px);
          padding-right: 8px;

          @media (min-width: 1280px) {
            margin-bottom: -5px;
          }

          @mixin media 901 {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &__page-decor {
          opacity: 0.2 !important;
        }

        &__image {
          img {
            object-position: top right;
          }
        }
      }
    }

    &.team-member-page {
      .first-section {
        &__image {
          top: ac(158px, 120px);

          &:after {
            content: none;
          }

          @mixin min-media 1400 {
            width: 44.54%;
          }

          @media (max-width: 639px) {
            top: 20px;

            &:after {
              content: '';
            }
          }
        }

        &__title {
          width: 100%;
          max-width: ac(580px, 380px);
        }

        &__page-decor {
          opacity: 0.2 !important;
        }
      }
    }

    &.sectors-page {
      .first-section {
        &__image {
          top: 0;
          left: 0;
          width: 100%;

          &:after {
            background: linear-gradient(
              180deg,
              #1c1c1c 0%,
              rgba(28, 28, 28, 0.8) 0.01%,
              rgba(28, 28, 28, 0.6) 48.5%,
              rgba(28, 28, 28, 0.8) 100%
            );
            opacity: 0.6;
            height: 100%;
            bottom: 0;
          }
        }

        &__wrap {
          position: relative;
          z-index: 10;
          padding-right: 0;
        }

        &__title {
          width: 100%;
          max-width: ac(890px, 480px);

          @media (min-width: 1280px) {
            letter-spacing: -1px;
          }
        }



        &__page-decor {
          opacity: 0.2 !important;
        }
      }
    }

    &.sector-page {

      .first-section {
        &__text {
          margin-bottom: ac(-7px, 0px);
          &.big-size-second {
            font-weight: 500;

            p, li {
              font-weight: 500;
            }
          }
        }
      }

      @media (min-width: 1280px) {
        .first-section__wrap {
          padding-right: 50%;

          @media (max-width: 1400px) {
            padding-right: 46%;
          }
        }
      }

      @media (min-width: 640px) {
        .first-section__color-bg {
          overflow: hidden;
        }

        .first-section__title {
          max-width: 90%;
        }

        .first-section__image-container {
          @media (min-width: 640px) {
            position: absolute;
            top: 29px;
            left: ac(-61px, -20px);
            @mixin aspect-ratio 457, 596;
            width: 90%;
            max-width: 457px;

            /*@mixin media 1300 {
              left: ac(-30px, -20px);
            }*/

            @media (max-width: 1400px) {
              left: -20px !important;
            }
          }

          &:after {
            position: absolute;
            inset: 0;
            z-index: 1;
            background: linear-gradient(
                    90deg,
                    rgba(28, 28, 28, 0.19) 0.01%,
                    rgba(28, 28, 28, 0) 100%
            );
            content: '';
          }
        }

        .first-section__image {
          @media (min-width: 640px) {
            top: ac(103px, 109px);
            background: var(--cl-beige);

            @mixin min-media 1430 {
              min-width: 577px;
            }

            &:after {
              content: none;
            }

            &:before {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              width: 100vw;
              transform: translateX(99%);
              background: var(--cl-beige);
              content: '';
            }
          }
        }

        .first-section__content {
          @media (min-width: 1280px) {
            padding-bottom: 9px;
            padding-right: 10px;
          }
        }
      }

      @media (max-width: 639px) {
        .first-section__image-container {
          position: absolute;
          inset: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .first-section__wrap {
          z-index: 10;
          position: relative;
        }
      }
    }

    &.insight-details-page {
      .first-section__image {
        right: auto;
        left: 0;

        @media (min-width: 640px) {
          z-index: 20;
        }
      }

      .first-section__color-bg {
        overflow: hidden;
        &:after {
          z-index: -1;
        }
      }

      .first-section__wrap {
        padding-right: 0;

        @mixin min-media 1930 {
          min-height: 900px;
        }

        @mixin media 1930 {
          @media (min-width: 1280px) {
            min-height: 88.95vh;
          }
        }

        @media (min-width: 640px) {
          padding-top: 133px;
          padding-bottom: 33px;
          padding-left: 44.28%;
        }
      }

      .first-section__top {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      .first-section__links {
        .link-btn {
          &:before {
            background-color: var(--cl-beige);
          }

          &__text {
            color: var(--cl-beige);
          }

          &__icon {
            color: var(--cl-beige);
          }
        }
      }

      .first-section__title {
        width: 100%;
        max-width: 100%;

        h1 {
          font-size: ac(60px, 40px);
          font-weight: 600;
          line-height: 116.67%;

          @mixin media 390 {
            font-size: 36px;
          }

          @mixin media 370 {
            font-size: 32px;
          }
        }
      }

      .first-section__content {
        width: 100%;
        margin-top: 0;
        padding-top: ac(50px, 30px);
        max-width: 100%;
        position: relative;

        &:after {
          position: absolute;
          z-index: 1;
          height: 1px;
          background: rgba(237, 233, 227, 0.1);
          content: "";
          transform: translateX(-50%);
          left: 50%;
          width: 200vw;
          top: 0;
        }

        &:not(:last-child) {
          margin-bottom: ac(30px, 24px);
        }
      }

      .first-section__page-decor {
        opacity: 0.05 !important;
      }

      .first-section__bottom {
        margin-top: auto;
        width: 100%;
        display: flex;
        padding-top: ac(30px, 20px);
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: ac(53px, 0px);
        grid-column-gap: ac(59px, 20px);
        grid-row-gap: 14px;
        position: relative;
        z-index: 10;

        &:after {
          position: absolute;
          z-index: 1;
          height: 1px;
          background: rgba(237, 233, 227, 0.1);
          content: "";
          transform: translateX(-50%);
          left: 50%;
          width: 200vw;
          top: 0;
        }

        &:not(:last-child) {
          margin-bottom: ac(30px, 24px);
        }

        @mixin media 1400 {
          flex-wrap: wrap;
          padding-right: 0;
        }

        @mixin media 1150 {
          align-items: center;
          justify-content: flex-start;
        }

        @mixin media 425 {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      .first-section__author {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        grid-column-gap: ac(15px, 12px);
        margin-right: auto;
      }

      .first-section__author-img {
        @mixin aspect-ratio 1, 1;
        width: ac(58px, 50px);
        border-radius: 50%;
        margin-bottom: -3px;
      }

      .first-section__author-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .first-section__author-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: var(--cl-primrose);

        &:not(:last-child) {
          margin-bottom: ac(7px, 6px);
        }
      }

      .first-section__author-name {
        font-weight: 400;
        font-size: ac(17px, 16px);
        line-height: normal;
        font-family: var(--font-second);

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }

      .first-section__date-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: var(--cl-primrose);

        &:not(:last-child) {
          margin-bottom: ac(7px, 6px);
        }
      }

      .first-section__date-info {
        font-weight: 400;
        font-size: ac(17px, 16px);
        line-height: normal;
        font-family: var(--font-second);

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }

      .first-section__share {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .social-link {
          border-color: var(--cl-primrose);
          background: var(--cl-primrose);

          &:before {
            background-color: var(--cl-beige);
          }

          &:hover {
            color: var(--cl-black);
          }
        }

        @mixin media 1150 {
          width: 100%;
        }
      }

      .first-section__share-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: var(--cl-primrose);

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }

  &.internal-second-type {
    .first-section {
      &__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 178px;
        padding-bottom: ac(80px, 50px);
        min-height: auto;
        border-bottom: 1px solid var(--cl-black);
        padding-right: 0;

        @media (min-width: 1280px) {
          padding-top: 183px;
        }
      }

      &__page-decor-container {
        @mixin aspect-ratio 1440, 1504;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        z-index: -1;
      }

      &__top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        @mixin media 551 {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          grid-row-gap: 20px;
        }
      }

      &__top-info {
        font-size: ac(18px, 16px);
        line-height: ac(28px, 22px);
        font-weight: 700;
        color: var(--cl-black);
        font-family: var(--font-main);
        opacity: 0.5;
      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        grid-column-gap: ac(7px, 4px);

        li {
          color: var(--cl-black) !important;
          font-family: var(--font-second);
          font-weight: 500;
          font-size: ac(24px, 22px);
          line-height: 1.42;
        }

        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }
      }

      &__bottom {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @mixin media 551 {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          grid-row-gap: 20px;
        }
      }

      &__share {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-column-gap: ac(30px, 20px);
        margin-bottom: ac(-10px, 0px);

        p {
          color: var(--cl-black) !important;
          font-family: var(--font-second);
          font-weight: 500;
          font-size: ac(24px, 22px);
          line-height: 1.42;
        }

        .socials {
          margin-top: ac(-7px, 0px);
        }
      }

      &__color-bg {
        &:after {
          content: none;
        }
      }

      &__title {
        h1 {
          color: var(--cl-black) !important;
        }
      }

      &__text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        li {
          color: var(--cl-black) !important;
        }

        p,
        li {
          a {
            &:hover {
              color: var(--cl-leaf) !important;
            }
          }
        }
      }

      &__page-decor {
        opacity: 0.05 !important;
        @mixin aspect-ratio 2170, 1504;
        position: absolute;
        left: -162px;
        width: 150.69%;
        max-width: 2170px;
        /*top: ac(-52px, -40px);*/
        top: ac(-197px, -80px);
        z-index: -1;

        @media (max-width: 639px) {
          width: 1440px;
          margin-left: -530px;
          top: -110px;
        }

        @mixin min-media 1600 {
          top: -200px;
        }

        @mixin min-media 1800 {
          top: -400px;
        }
      }

      &__title {
        text-align: left;
        max-width: ac(1040px, 600px);
      }

      &__content {
        margin-top: auto;
        width: 100%;
        max-width: 100%;
        position: relative;
        z-index: 5;
      }
    }

    &.job-details-page {
      .first-section {
        &__wrap {
          padding-bottom: ac(51px, 40px);
        }

        &__title {
          max-width: ac(980px, 620px);

          h1 {
            font-size: ac(70px, 40px);
            font-weight: 600;
            line-height: 1.11;
          }

          &:not(:last-child) {
            margin-bottom: ac(33px, 24px);
          }
        }
      }
    }

    &.banner-type {
      .first-section {

        &__bg-container {
          margin-top: ac(173px, 140px);
          position: relative;
          z-index: 1;
          padding-bottom: ac(70px, 30px);

          &:before {
            bottom: 0;
            left: ac(47px, 10px);
            right: ac(52px, 10px);
            background: var(--cl-black);
            content: "";
            height: 1px;
            position: absolute;
          }
        }

        &__bg-image {
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: ac(70px, 30px);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media (min-width: 1600px) {
              object-position: 50% 10%;
            }

            @media (min-width: 1800px) {
              object-position: 50% 20%;
            }

            @media (min-width: 1900px) {
              object-position: 50% 25%;
            }
          }

          &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(269.94deg, rgba(0, 0, 0, 0) 26.54%, rgba(0, 0, 0, 0.7) 99.91%);
            z-index: 1;
          }
        }

        &__wrap {
          position: relative;
          z-index: 10;
          padding-top: ac(173px, 140px);
          padding-bottom: ac(70px, 50px);
          min-height: ac(693px, 450px);
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          flex-direction: row-reverse;
          /*border-bottom: none;*/

          /*&:before {
            display: none !important;
          }*/

          /*@media (max-width: 1024px) {
            justify-content: center;
          }*/

          @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 57.33%;
          margin-top: 0;

          @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 30px;
          }
        }

        &__image {
          width: 100%;
          @mixin aspect-ratio 495, 450;

          @media (min-width: 769px) {
            @mixin aspect-ratio 495, 450;
            width: 39.86%;
          }

          @media (max-width: 768px) {
            max-width: 100%;
          }
        }

        &__title {
          width: 100%;
          max-width: 100% !important;

          h1 {
            color: var(--cl-black) !important;
            font-size: ac(70px, 38px);
            font-weight: 600;
            line-height: 1.11;
            font-family: var(--font-main);
          }
        }

        &__equities-container {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: auto;
        }

        &__equities-list {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          grid-column-gap: ac(102px, 40px);
          grid-row-gap: 20px;
          width: calc(100% - 150px);
        }

        &__equities-item {
          align-items: flex-start;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          grid-row-gap: 7px;
        }

        &__equities-item-title {
          color: var(--cl-teal);
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }

        &__equities-item-text {
          color: var(--cl-black);
          font-family: var(--font-second);
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 22px;
        }

        &__equities-socials-container {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          display: flex;
          grid-row-gap: 4px;
        }

        &__equities-socials {
          .social-link {
            border-color: var(--cl-teal);
            background-color: var(--cl-teal);

            &:before {
              background-color: var(--cl-deep-teal);
            }

            &:hover {
              color: var(--cl-beige);
            }
          }
        }

      }

      /*+ .first-section.internal-second-type.banner-type {
        .first-section__bg-container {
          margin-top: 20px !important;

        }
      }*/

      &.banner-second-type {

        --small-img: ac(300px, 150px);
        --standard-img: ac(450px, 380px);
        --medium-img: ac(600px, 350px);
        --big-img: ac(700px, 400px);
        --biggest-img: ac(800px, 450px);

        --image-height: var(--standard-img);

        .first-section {
          &__wrap {
            padding-top: ac(50px, 50px);
            padding-bottom: ac(50px, 40px);
            min-height: var(--image-height);
            border-bottom: none;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @media (min-width: 1800px) {
              --standard-img: 550px;
            }

            @media (min-width: 1900px) {
              --standard-img: 600px;
            }

            @media (min-width: 2000px) {
              --standard-img: 650px;
            }

            &:before {
              display: none !important;
            }
          }

          &__title {
            width: 100%;
            max-width: ac(700px, 400px) !important;
            margin-right: auto;

            h1 {
              color: var(--cl-beige) !important;
            }
          }
        }
      }

      &.pb-type {
        --none: 0;
        --small: ac(80px, 40px);
        --standard: ac(173px, 140px);
        --medium: ac(100px, 50px);
        --big: ac(120px, 60px);
        --biggest: ac(150px, 80px);

        --gap: var(--standard);

        &:not(.banner-second-type) {
          .first-section__wrap {
            padding-top: var(--gap);
          }
        }

        &.banner-second-type {
          padding-top: 0 !important;
          .first-section__wrap {
            padding-top: var(--gap);
            padding-bottom: var(--gap);
          }

          .first-section__bg-container {
            padding-top: 0;
          }

        }
      }
    }

    &.contact-page {
      .first-section {
        &__wrap {
          padding-bottom: ac(80px, 40px);
          border-bottom: none;
        }

        &__color-bg {
          background-color: var(--cl-beige);
        }

        &__title {
          max-width: 100%;
          margin-top: ac(-5px, 0px);

          &:not(:last-child) {
            margin-bottom: ac(39px, 24px);
          }
        }

        &__text {
          width: 100%;
          max-width: ac(818px, 590px);
        }
      }
    }

    &.insights-page {
      border-bottom: 1px solid var(--cl-border-color);
      .first-section {
        &__wrap {
          padding-bottom: ac(70px, 40px);
          border-bottom: none;
        }

        &__title {
          max-width: 100%;
          margin-top: ac(-5px, 0px);

          &:not(:last-child) {
            margin-bottom: ac(39px, 24px);
          }
        }
      }
    }

    &.job-search-page {
      .first-section {
        &__title {
          h1 {
            @mixin media 425 {
              font-size: 35px;
            }

            @mixin media 360 {
              font-size: 32px;
            }
          }
        }
      }
    }

    &.faq-page {
      .first-section {
        &__wrap {
          align-items: center;
          justify-content: flex-start;
          border-bottom: none;
          padding-bottom: ac(32px, 20px);
        }

        &__title {
          width: 100%;
          text-align: center;
          margin-top: ac(-6px, 0px);
          
          &:not(:last-child) {
            margin-bottom: ac(40px, 30px);
          }

          &.title-animation .title-line::before {
            height: ac(28px, 12px);
          }
        }

        &__text {
          width: 100%;
          max-width: ac(818px, 700px);
          text-align: center;
        }
      }
    }
  }
}
