.text-section {
  position: relative;
  z-index: 10;
  overflow: hidden;

  &__page-decor {
    @mixin aspect-ratio 766.77, 879.48;
    position: absolute;
    z-index: -1;
    /*top: ac(-170px, -100px);*/
    top: ac(-150px, -180px);
    width: ac(766.77, 400px);
    left: ac(-206px, -100px);

    @media (max-width: 639px) {
      left: 0;
      width: 88.8%;
      max-width: 400px;
    }
  }

  &__wrap {
    display: flex;
  }

  &__title {
  }

  &__subtitle {
    font-size: ac(40px, 26px);
    font-weight: 600;
    line-height: 1.25;
    color: var(--cl-leaf);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (min-width: 1400px) {
      letter-spacing: -0.01px;
    }
  }

  &__buttons {
  }

  &__lines-container {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    clip-path: inset(-1px -100% -1px -100%);
    position: relative;

    &:after, &:before {
      position: absolute;
      z-index: 1;
      height: 1px;
      background: rgba(0, 52, 35, 0.1);
      content: "";
      transform: translateX(-50%);
      left: 50%;
      width: 100vw;
    }

    &:after {
      top: 0;
    }

    &:before {
      bottom: 0;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__left-lines-block {
    width: 47.42%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(50px, 30px);
    padding-right: ac(89px, 20px);
    padding-bottom: ac(56px, 30px);
    border-right: 1px solid var(--cl-border-color);
    clip-path: inset(-1px -1px -1px -100vw);

    .text-section {
      &__text {
        margin: 0;
        width: 100%;
        
        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }

        &.bigger-size {
          color: var(--cl-teal);

          font-weight: 400;
          font-size: ac(33px, 24px);
          line-height: 130%;
          letter-spacing: 0.05em;

          p {
            font-weight: 400;
            font-size: ac(33px, 24px);
            line-height: 130%;
            letter-spacing: 0.05em;
          }

          p, li {
            color: var(--cl-teal);
          }
        }

        &.big-size-second {
          margin-top: auto;
          position: relative;
          padding-top: ac(56px, 30px);

          color: var(--cl-clementine);

          p, li {
            color: var(--cl-clementine);
          }

          &:after {
            position: absolute;
            z-index: 1;
            height: 1px;
            background: var(--cl-border-color);
            content: "";
            transform: translateX(-50%);
            left: 50%;
            width: 100vw;
            top: 0;
          }
        }
      }
    }

    @media (max-width: 767px) {
      clip-path: none;
      width: 100%;
    }
  }

  &__right-lines-block {
    /*width: 52.58%;*/
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(50px, 30px);
    padding-left: ac(47px, 20px);
    padding-bottom: ac(51px, 30px);
    position: relative;
    border-left: 1px solid #DDE0DC;
    margin-left: -1px;

    .text-section {
      &__text {
        margin: 0;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: ac(40px, 24px);
        }
      }
    }

    @media (max-width: 767px) {
      border-left: 0;
      padding-left: 0;
      width: 100%;

      &:after {
        position: absolute;
        z-index: 1;
        height: 1px;
        background: var(--cl-border-color);
        content: "";
        transform: translateX(-50%);
        left: 50%;
        width: 100vw;
        top: 0;
      }
    }
  }

  &.home-page {
    margin-top: ac(50px, 40px);
    padding-top: ac(37px, 40px);
    padding-bottom: ac(40px, 20px);
    overflow: hidden;

    @media (max-width: 639px) {
      margin-top: 0;
    }

    .text-section {
      &__wrap {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
      }

      &__title-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }
      }

      &__title {
        width: 100%;
        max-width: ac(720px, 460px);
        text-align: left;
      }
    }
  }

  &.dark-bg.classroom-page {
    padding-top: ac(40px, 20px);
    padding-bottom: ac(40px, 20px);

    .text-section {
      &__color-bg {
        background: #023532;
      }

      &__title {
        h2 {
          .word {
            &:first-child {
              &:before {
                display: none !important;
              }

              margin-left: 0 !important;
            }
          }
        }
      }

      &__wrap {
        padding-top: ac(110px, 30px);
        padding-bottom: ac(110px, 30px);
        position: relative;
        z-index: 5;

        @media (min-width: 769px) {
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;

          .text-section {
            &__title {
              width: 38.65%;

              h2 {
                font-family: var(--font-third) !important;
              }
            }

            &__content {
              width: 57.33%;
            }
          }
        }

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          grid-row-gap: 24px;
        }
      }
    }

    @media (max-width: 639px) {
      margin-bottom: 0;
    }
  }

  &.kernel-about-page {
    margin-top: ac(95px, 40px);
    padding-top: ac(37px, 40px);
    padding-bottom: ac(40px, 20px);
    overflow: hidden;

    @media (max-width: 639px) {
      margin-top: 0;
    }

    .text-section {
      &__wrap {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
      }

      &__title-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:not(:last-child) {
          margin-bottom: ac(54px, 30px);
        }
      }

      &__title {
        width: 100%;
        max-width: ac(940px, 560px);
        text-align: left;
      }

      &__left-lines-block {
        width: 52.58%;
        padding-top: ac(50px, 30px);
        padding-right: ac(47px, 20px);
        padding-bottom: ac(50px, 30px);
        border-right: 1px solid var(--cl-border-color);
        clip-path: inset(-1px -1px -1px -100vw);

        @media (max-width: 767px) {
          clip-path: none;
          width: 100%;
        }

        .text-section {
          &__text {
            width: calc(100% + 1px);
          }
        }
      }

      &__right-lines-block {
        /*width: 52.58%;*/
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: ac(50px, 30px);
        padding-bottom: ac(50px, 30px);
        position: relative;

        @media (min-width: 768px) {
          padding-left: ac(89px, 20px);
          border-left: 1px solid #DDE0DC;
          margin-left: -1px;
        }

        .text-section {
          &__text {
            &.content-element {
              h3 {
                font-weight: 600;
                font-size: ac(25px, 22px);
                line-height: 1.4;
                font-family: var(--font-main);
                
                &:not(:last-child) {
                  margin-bottom: ac(30px, 20px);
                }
              }
            }
          }
        }

        /*.text-section {
          &__text {
            margin: 0;
            width: 100%;

            &:not(:last-child) {
              margin-bottom: ac(40px, 24px);
            }
          }
        }

        @media (max-width: 767px) {
          border-left: 0;
          padding-left: 0;
          width: 100%;

          &:after {
            position: absolute;
            z-index: 1;
            height: 1px;
            background: var(--cl-border-color);
            content: "";
            transform: translateX(-50%);
            left: 50%;
            width: 100vw;
            top: 0;
          }
        }*/
      }
    }

    + .our-brands-section.about-page {
      margin-top: ac(-40px, -20px);
    }
  }

  &.dartmouth-page {
    .text-section__left-lines-block .text-section__text.bigger-size {
      color: var(--cl-leaf);

      p, li {
        color: var(--cl-leaf);
      }
    }

    .text-section__left-lines-block .text-section__text.big-size-second {
      color: var(--cl-leaf);

      p, li {
        color: var(--cl-leaf);
      }
    }
  }

  &.pure-page {
    .text-section__left-lines-block .text-section__text.bigger-size {
      color: var(--cl-royal);

      p, li {
        color: var(--cl-royal);
      }
    }

    .text-section__left-lines-block .text-section__text.big-size-second {
      color: var(--cl-royal);

      p, li {
        color: var(--cl-royal);
      }
    }
  }

  &.catalyst-page {
    .text-section__left-lines-block .text-section__text.bigger-size {
      color: var(--cl-tangerine);

      p, li {
        color: var(--cl-tangerine);
      }
    }

    .text-section__left-lines-block .text-section__text.big-size-second {
      color: var(--cl-tangerine);

      p, li {
        color: var(--cl-tangerine);
      }
    }
  }



  &.about-page {
    padding-top: ac(100px, 40px);
    padding-bottom: ac(40px, 20px);

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        grid-row-gap: ac(35px, 32px);
      }

      &__title {
        width: 100%;
        max-width: ac(712px, 310px);
        text-align: left;

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__content {
        width: 65.86%;
        margin-left: auto;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(50px, 24px);
        }
      }
    }

    &.about-page:not(.group-type) {
      .text-section {
        &__buttons {
          @mixin media 390 {
            width: 100%;

            .btn {
              width: 100%;

              &__text {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }

    &.group-type {
      overflow: visible;
      z-index: 2;
      padding-top: ac(40px, 20px);

      @media (min-width: 768px) {
        .text-section {
          &__wrap {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
          }

          &__title {
            width: 39.13%;
          }

          &__content {
            padding-top: ac(66px, 40px);
            width: 57.33%;
          }
        }
      }

      .text-section {
        &__group-list {
          padding-top: ac(50px, 40px);
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: center;
          grid-row-gap: 30px;
          grid-column-gap: ac(30px, 20px);
          flex-wrap: wrap;

          .group-card {
            width: calc(32.9% - ac(15px, 10px));

            @mixin media 1100 {
              width: calc(32.6% - ac(15px, 10px));
            }

            @mixin media 851 {
              width: calc(50% - ac(15px, 10px));
            }

            @mixin media 551 {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.our-purpose-page {
    padding-top: ac(100px, 40px);
    padding-bottom: ac(38px, 20px);

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        grid-row-gap: ac(35px, 32px);
      }

      &__title {
        width: 100%;
        max-width: ac(712px, 310px);
        text-align: left;

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__subtitle {
        width: 100%;

        @media (min-width: 1280px) {
          margin-bottom: -3px;
          margin-top: -3px;
        }
      }

      &__content {
        width: 65.86%;
        margin-left: auto;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      &__text {
        &:not(:last-child) {
          margin-bottom: ac(50px, 24px);
        }

        @media (min-width: 1280px) {
          padding-right: 22px;
        }
      }
    }
  }

  &.sector-page {
    padding-top: ac(100px, 40px);
    padding-bottom: ac(38px, 20px);

    .text-section {
      &__wrap {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        grid-row-gap: ac(35px, 32px);
      }

      &__title {
        width: 100%;
        max-width: 100%;
        text-align: left;

        h2 {
          br {
            + span {
              margin-left: ac(28px, 24px);
            }
          }
        }
      }

      &__content {
        width: 65.86%;
        margin-left: auto;

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}

.only-text-section {
  padding-top: ac(80px, 40px);
  padding-bottom: ac(40px, 20px);
  position: relative;
  overflow: hidden;
  z-index: 20;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: ac(818px, 650px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &.second-type {
    .only-text-section {
      &__wrap {
        max-width: 100%;
      }
    }
  }

  &.columns-type,
  &.form-type {
    .only-text-section {
      &__wrap {
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        grid-column-gap: ac(50px, 30px);
        grid-row-gap: 30px;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
      }
    }
  }

  &.form-type {
    .only-text-section {
      &__wrap {
        flex-direction: row;

        @media (max-width: 851px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__content {
        .form-equities-section {
          &__form-grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @media (max-width: 1024px) {
              grid-template-columns: repeat(1, minmax(0, 1fr));
            }
          }
        }

        .hbspt-form {
          width: 100%;
        }
      }
    }

  }

  &.pb-type {
    --none: 0;
    --small: ac(40px, 20px);
    --standard: ac(80px, 40px);
    --medium: ac(100px, 50px);
    --big: ac(120px, 60px);
    --biggest: ac(150px, 80px);

    --gap: var(--standard);

    padding-top: var(--gap);
    padding-bottom: 0;
  }
}
