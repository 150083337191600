.logos-section {
  position: relative;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__slider-buttons {
    .slider-btn {
      margin-top: ac(32px, 26px);
    }
  }

  &__slider {
    overflow: visible;

    &:not(.swiper-initialized) {
      display: none;
    }
  }

  &__item {
    padding-top: ac(30px, 20px);
    padding-bottom: ac(30px, 20px);
    border-top: 1px solid var(--cl-leaf);
    border-bottom: 1px solid var(--cl-leaf);
  }

  &__item-image {
    @mixin aspect-ratio 224.4, 144;
    width: 100%;

    img {
      object-fit: contain;
    }

    @media (max-width: 639px) {
      @mixin aspect-ratio 168, 100;
    }
  }

  &.home-page {
    padding-top: ac(36px, 16px);
    padding-bottom: ac(26px, 38px);
  }
}
