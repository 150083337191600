.offices-section {
  padding-top: ac(45px, 20px);
  padding-bottom: 40px;

  position: relative;
  z-index: 10;
  overflow: hidden;



  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  &__title {
    display: none;
  }

  &__title-container {
    width: 100%;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__title-item {
    font-size: ac(70px, 40px);
    font-weight: 600;
    line-height: 1.11;
    font-family: var(--font-main);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;

    &.active {
      position: relative;
      left: auto;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .filters-slider {
      &__item {
        &.active {
          .filters-slider__item-btn {
            opacity: 1;
            font-weight: 600;
          }
        }
      }

      &__item-btn {
        opacity: 0.5;
        font-weight: 400;
        font-size: ac(24px, 22px);
        line-height: 1.42;
        font-family: var(--font-main);
      }
    }
  }

  &__container {
    width: 100%;
    position: relative;
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.3s ease;
    pointer-events: none;

    &.active {
      position: relative;
      left: auto;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__item-top-text {
    width: 100%;
    max-width: ac(940px, 600px);

    &.big-size {
      p,
      li {
        font-size: ac(30px, 20px);
        line-height: 133.33%;
        font-weight: 400;
        font-family: var(--font-main);

        /*@media (min-width: 1280px) {
          letter-spacing: -0.5px;
        }*/
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__item-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column-gap: ac(30px, 15px);
    grid-row-gap: ac(30px, 24px);

    @mixin media 1200 {
      flex-wrap: wrap;
    }
  }

  &__item-info {
    width: 40.26%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin media 1200 {
      width: 100%;
    }
  }

  &__item-text {
    &:not(:last-child) {
      margin-bottom: ac(25px, 22px);
    }
  }

  &__item-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-link-name {
    font-size: ac(16px, 14px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-teal);

    &:not(:last-child) {
      margin-bottom: ac(10px, 8px);
    }
  }

  &__item-link {
    color: var(--cl-black);
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: ac(24px, 20px);
    line-height: 1.42;
    font-family: var(--font-main);

    &:hover {
      color: var(--cl-clementine) !important;
    }

    &:not(:last-child) {
      margin-bottom: ac(36px, 14px);
    }

    @mixin media 1200 {
      @media (min-width: 768px) {
        br {
          display: none;
        }
      }
    }

    @media (max-width: 360px) {
      font-size: 18px;
    }
  }

  &__item-map-container {
    width: 57.33%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @mixin media 1200 {
      width: 100%;
      max-width: 712px;
    }

    @mixin media 551 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 20px;
    }
  }

  &__item-map {
    @mixin aspect-ratio 394, 462;
    width: 55.34%;

    @mixin media 551 {
      width: 100%;
      max-width: 100%;
    }
  }

  &__item-image {
    @mixin aspect-ratio 288, 462;
    width: 40.45%;
    max-width: 288px;

    @mixin media 551 {
      @mixin aspect-ratio 350, 440;
      width: 100%;
      max-width: 390px;
    }
  }

  &.only-london-type {
    .offices-section {
      &__wrap {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 951px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__title {
        display: flex;
        width: 100%;
        padding-right: ac(439px, 200px);

        &:not(:last-child) {
          margin-bottom: ac(30px, 24px);
        }

        @media (max-width: 768px) {
          padding-right: 0;
        }
      }

      &__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 57.33%;

        @media (max-width: 951px) {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
      }
      
      &__item-top-text {

        width: 100%;
        max-width: ac(572px, 420px);

        &:not(:last-child) {
          margin-bottom: ac(30px, 24px);
        }
      }

      &__item-map {
        @mixin aspect-ratio 500, 521;
        width: 40.26%;

        @media (max-width: 951px) {
          width: 100%;
          max-width: 500px;
        }

      }

      &__links-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 30px;

        @media (max-width: 640px) {
          flex-direction: column;
          grid-row-gap: 14px;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      &__item-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        
        &:first-child {
          @media (min-width: 1420px) {
            min-width: 394px;
          }
        }
      }
    }
  }
}
