.our-brands-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(80px, 40px);
  border-bottom: 1px solid var(--cl-border-color);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__color-bg {
    background: var(--cl-deep-teal);
    overflow: hidden;
  }

  &__wrap {
    padding-top: ac(110px, 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(60px, 30px);
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    justify-content: center;
    --px: ac(50px, 20px);
    position: relative;

    &:after {
      position: absolute;
      z-index: 1;
      height: 1px;
      background: rgba(237, 233, 227, 0.3);
      content: "";
      transform: translateX(-50%);
      left: 50%;
      width: 100vw;
      top: 0;
    }
  }

  &__item {
    width: 32.93%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(106px, 70px);
    position: relative;

    &:first-child {
      padding-right: var(--px);
    }

    &:last-child {
      padding-left: var(--px);

      .our-brands-section__item-logo {
        left: var(--px);
      }
    }

    &:nth-child(2) {
      z-index: 5;
      &:not(:first-child), &:not(:last-child) {
        width: auto;
        flex: 1;
        padding-left: var(--px);
        padding-right: var(--px);
        border-left: 1px solid rgba(237, 233, 227, 0.3);
        border-right: 1px solid rgba(237, 233, 227, 0.3);

        .our-brands-section__item-logo {
          left: var(--px);
        }
      }
    }

    &.catalyst-type {
      .our-brands-section__item-logo {
        width: 140px;
      }

      .our-brands-section__item-bottom {
        &:before {
          left: -100vw;
          background-color: var(--cl-tangerine);
        }
      }

      .our-brands-section__item-decor {
        width: 121px;
      }
    }

    &.dartmouth-type {
      .our-brands-section__item-logo {
        width: 196px;
      }

      .our-brands-section__item-decor {
        width: 105px;
      }
    }

    &.pure-type {
      .our-brands-section__item-logo {
        width: 81px;
      }

      .our-brands-section__item-bottom {
        &:before {
          right: -100vw;
          background-color: var(--cl-royal);
        }
      }

      .our-brands-section__item-decor {
        width: 121px;
        right: ac(-47px, -30px);
      }
    }
  }

  &__item-logo {
    position: absolute;
    top: ac(35px, 24px);
    left: 0;
  }

  &__item-text {
    margin-bottom: ac(50px, 30px);
  }

  &__item-bottom {
    margin-top: auto;
    padding-top: ac(35px, 30px);
    padding-bottom: ac(35px, 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: ac(170px, 160px);
    position: relative;
    z-index: 1;
    width: 100%;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(var(--px) * -1);
      right: calc(var(--px) * -1);
      background: var(--cl-mint);
      z-index: -1;
      content: "";
    }
  }

  &__item-decor {
    position: absolute;
    z-index: 5;
    right: 0;
    bottom: 0;
  }

  &__info-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

  }

  &__info-item-text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: var(--px);
  }

  &__info-item-buttons {
    border-top: 1px solid var(--cl-border-color);
    padding-top: ac(25px, 24px);
    padding-bottom: ac(25px, 24px);
    padding-left: var(--px);
    padding-right: var(--px);
    width: calc(100% + var(--px));
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @media (max-width: 1020px) {
    .our-brands-section {
      &__list {
        --px: 0;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__item {
        width: 100%;

        &:first-child {
          padding-right: 0;
        }

        &:last-child {
          padding-left: 0;

          .our-brands-section__item-logo {
            left: 0;
          }
        }

        &:nth-child(2) {
          z-index: 5;
          &:not(:first-child), &:not(:last-child) {
            width: auto;
            flex: 1;
            padding-left: 0;
            padding-right: 0;
            border-left: none;
            border-right: none;

            .our-brands-section__item-logo {
              left: 0;
            }
          }
        }

        &.catalyst-type {
          .our-brands-section__item-decor {
            width: 121px;
          }
        }

        &.dartmouth-type {
          .our-brands-section__item-decor {
            width: 105px;
          }
        }

        &.pure-type {
          .our-brands-section__item-decor {
            width: 121px;
            right: ac(-47px, -30px);
          }
        }
      }

      &__item-decor {
        right: ac(-47px, -30px);
      }

      &__item-bottom {
        &:before {
          left: -100px;
          right: -100px;
        }
      }
    }
  }

  &.about-page {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid var(--cl-border-color);
    margin-bottom: ac(40px, 20px);

    .cont {
      /*@media (min-width: 801px) {
        overflow: hidden;
      }*/

      @media (min-width: 640px) {
        border-right: 1px solid var(--cl-border-color);
        border-left: 1px solid var(--cl-border-color);
      }
    }

    .our-brands-section {
      &__wrap {
        padding-top: 0;
      }

      &__title {
        &:not(:last-child) {
          margin-bottom: ac(33px, 30px);
        }
      }

      &__grid {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: 0;
        grid-row-gap: 0;
        width: 100%;
        --px: ac(47px, 20px);

        @media (min-width: 1420px) {
          grid-template-columns: 23.03% 26.89% 26.89% 23.03%;
        }
        
        @media (max-width: 1279px) {
          --px: 20px;
        }

        @media (max-width: 1100px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));

          @media (min-width: 801px) {
            .our-brands-section__item {
              padding-right: var(--px) !important;

              &:nth-child(3) {
                width: calc(100% + 40px) !important;
              }
            }

            .our-brands-section__item-decor {
              right: 0 !important;
            }

            .our-brands-section__item:nth-child(2):not(:first-child), .our-brands-section__item:nth-child(2):not(:last-child) {
              padding-left: var(--px);
              padding-right: var(--px);

              .our-brands-section__item-logo {
                left: var(--px);
              }
            }
          }
        }

        @media (max-width: 800px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__item {
        padding-top: ac(121px, 70px);
        width: 100% !important;
        border: none !important;

        @media (min-width: 801px) {
          border-right: 1px solid var(--cl-border-color) !important;
        }

        &:nth-child(3) {
          @media (min-width: 801px) {
            padding-left: var(--px);
            padding-right: var(--px);
          }

          .our-brands-section__item-logo {
            left: var(--px);
          }
        }

        &:not(:first-child) {
          @media (min-width: 801px) {
            overflow: hidden;
          }
        }

        &.catalyst-type {
          .our-brands-section__item-decor {
            width: ac(86px, 30px);

            @media (max-width: 800px) {
              width: 86px;
            }
          }
        }

        &.dartmouth-type {
          .our-brands-section__item-decor {
            width: ac(75px, 26px);

            @media (max-width: 800px) {
              width: 75px;
            }
          }
        }

        &.pure-type {
          .our-brands-section__item-decor {
            width: ac(86px, 30px);

            @media (min-width: 801px) {
              right: 0;
            }

            @media (max-width: 800px) {
              width: 86px;
            }
          }
        }
      }

      &__item-logo {
        top: ac(50px, 24px);
      }

      &__item-bottom {
        min-height: ac(102px, 90px);
      }

      &__info-item-text {
        @media (min-width: 1420px) {
          min-height: 135px;
        }
      }

      &__info-item-buttons {
        width: calc(100% + 40px);

        @media (max-width: 1100px) {
          width: 100%;
        }
      }

      &__info-item {
        @media (max-width: 1100px) {
          width: calc(100% + 80px);
          margin-left: -40px;
          grid-column: 1 / -1;

          @media (min-width: 769px) {
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;

            .our-brands-section__info-item-buttons {
              width: auto;
            }

            @media (max-width: 1024px) {
              width: calc(100% + 40px);
              margin-left: -20px;
            }
          }
        }

        @media (max-width: 768px) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}