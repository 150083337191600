.faq-section {
  position: relative;
  z-index: 10;
  margin-top: ac(40px, 20px);
  margin-bottom: ac(40px, 20px);
  border-top: 1px solid var(--cl-border-color);
  border-bottom: 1px solid var(--cl-border-color);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__title-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding-top: ac(50px, 30px);
    clip-path: inset(0 -1px 0 -100vw);
    width: 23.19%;
    border-right: 1px solid var(--cl-border-color);

    @media (max-width: 640px) {
      clip-path: inset(0 -100% 0 -100%);
      width: 100%;
      border-right: 0;
    }
  }

  &__title {
    width: 100%;
    padding-bottom: ac(50px, 30px);
    padding-right: 20px;
    position: relative;

    &:before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 200vw;
      height: 1px;
      background: var(--cl-border-color);
      content: "";
      z-index: 1;
      bottom: 0;
    }
  }

  &__decor {
    @mixin aspect-ratio 288, 398;
    width: 100%;

    &:not(:first-child) {
      margin-top: ac(50px, 30px);
    }

    @media (max-width: 640px) {
      display: none;
    }
  }

  &__accordion-content {
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 30px);
    width: 73.03%;
    .faq-section {
      &__accordion {
        width: 100%;
      }
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__category {
    width: 100%;
    max-width: ac(1030px, 950px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    &:not(:last-child) {
      margin-bottom: ac(80px, 40px);
    }
  }

  &__category-title-block {
    background: var(--cl-black);
    width: 100%;
    padding: 10px;
    &:not(:last-child) {
      margin-bottom: ac(40px, 20px);
    }
  }

  
  &__accordion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: ac(21px, 15px);
    transition: min-height 0.4s ease;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    transition: background-color 0.4s ease, box-shadow 0.3s ease, padding 0.4s ease;
    position: relative;
    z-index: 2;
    padding-bottom: ac(20px, 15px);
    border-bottom: 1px solid var(--cl-black);

    &.active {
      .faq-section__item-top {
        margin-bottom: ac(25px, 14px);
      }

      .faq-section__item-btn {
        i {
          color: var(--cl-clementine);
          &.icon-plus {
            opacity: 0;
          }
        }
      }

      .faq-section__item-title {
        color: var(--cl-clementine);
      }

      .faq-section__item-content {
        opacity: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;
      &:hover {
        .faq-section__item-title {
          color: var(--cl-teal);
        }
      }

      .faq-section__item-content {
        max-height: 0 !important;
      }

      .faq-section__item-btn {
        i {
          &.icon-minus {
            opacity: 0;
          }
        }
      }
    }
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-bottom 0.4s ease, margin 0.4s ease;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
  }

  &__item-btn {
    --size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;

    i {
      font-size: 25px;
      transition: all 0.4s ease;
      color: var(--cl-black);
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }

    &:hover {
      i {
        color: var(--cl-teal);
      }
    }
  }

  &__item-title {
    width: calc(100% - 40px);
    font-size: ac(30px, 20px);
    line-height: 1.33;
    font-weight: 600;
    font-family: var(--font-main);
    transition: all 0.3s ease;
    color: var(--cl-black);
  }

  &__item-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*max-height: 0;*/
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.4s ease;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__item-text {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(22px, 18px);
    }

    + .faq-section__item-links {
      padding-bottom: 5px;
    }
  }

  @media (max-width: 639px) {
    &:last-child {
      margin-bottom: 30px;
    }
  }
}