.teams-hub-section {
  padding-top: ac(130px, 50px);
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  z-index: 10;

  + .text-block-section {
    padding-top: 0 !important;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(55px, 30px);
    }
  }

  &__search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: ac(80px, 36px);
    }
  }

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(30px, 20px);
    grid-row-gap: 20px;

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;

    .filters-slider__item-btn {
      font-weight: 400;
      transition: all 0.25s ease;
    }

    .filters-slider__item input:checked + .filters-slider__item-btn {
      font-weight: 600;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 0;
    grid-row-gap: 0;
    position: relative;
    width: calc(100% + ac(30px, 0px));

    &:after, &:before {
      position: absolute;
      z-index: 1;
      height: 1px;
      background: var(--cl-border-color);
      content: "";
      transform: translateX(-50%);
      left: 50%;
      width: 100vw;
    }

    &:after {
      top: -1px;
    }

    &:before {
      bottom: -1px;
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .team-card {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      padding: ac(50px, 24px) ac(47px, 20px);
      position: relative;
      overflow: visible;

      &:after {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        content: "";
        border: 1px solid var(--cl-border-color);
        inset: -1px;
      }

      &__image {
        @mixin aspect-ratio 288, 341;
        width: 53.14%;

        @media (max-width: 951px) {
          width: 40%;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 41.33%;
        height: 100%;

        @media (max-width: 951px) {
          width: 55%;
        }
      }

      &__links {
        margin-top: auto;
      }

      &__title {
        @media (max-width: 951px) {
          font-size: 22px;
        }
      }

      @media (max-width: 425px) {
        padding-left: 0;
        padding-right: 0;

        &:after {
          left: -40px;
          right: -40px;
        }
      }
    }
  }

  &__second-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__second-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(30px, 20px);
    grid-row-gap: ac(50px, 24px);
    position: relative;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: 640px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    cursor: pointer;

    &.active {
      .teams-hub-section {
        &__item-content {
          border-color: var(--cl-teal);
        }

        &__item-title {
          color: var(--cl-teal);
        }

        &__item-btn {
          background-color: var(--cl-teal);
          i {
            transform: scaleY(-1);
          }
        }
      }
    }

    &:hover {
      .teams-hub-section {
        &__item-content {
          border-color: var(--cl-clementine);
        }

        &__item-title {
          color: var(--cl-clementine);
        }

        &__item-image {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
    
    @media (max-width: 640px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__item-image {
    @mixin aspect-ratio 394, 341;
    width: 100%;

    img {
      transition: transform .3s ease;
    }
    
    &:not(:last-child) {
      margin-bottom: ac(20px, 15px);
    }

    @media (max-width: 640px) {
      width: 44%;
    }

    @media (max-width: 451px) {
      width: 40%;
    }
  }

  &__item-content {
    padding-top: 12px;
    border-top: 1px solid var(--cl-black);
    transition: border-color .3s ease;
    padding-right: ac(50px, 45px);
    width: 100%;
    position: relative;

    @media (max-width: 640px) {
      width: 56%;
      padding-left: 20px;
    }

    @media (max-width: 451px) {
      width: 60%;
      padding-left: 18px;
    }
  }

  &__item-btn {
    --size: ac(41px, 38px);
    width: ac(41px, 38px);
    height: ac(41px, 38px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-black);
    border-radius: 0 0 ac(15px, 12px) ac(15px, 12px);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transition: background-color .3s ease;

    i {
      font-size: ac(17px, 16px);
      transition: transform .3s ease;
      color: var(--cl-white);
    }

    &:hover {
      background-color: var(--cl-clementine);
    }
  }

  &__item-title {
    font-family: var(--font-main);
    font-size: ac(30px, 22px);
    line-height: 1.33;
    font-weight: 600;
    @mixin max-line-length 4;
    transition: color .3s ease;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__item-subtitle {
    font-family: var(--font-main);
    font-size: ac(20px, 17px);
    line-height: 130%;
    font-weight: 500;
    @mixin max-line-length 4;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__item-container {
    display: none;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 16px;
    }
  }

  &__item-links {
    padding-top: ac(4px, 0px);
    width: 23.19%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    grid-row-gap: 10px;

    @media (max-width: 801px) {
      width: 33%;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__item-link {
    font-family: var(--font-main);
    font-size: 16px;
    line-height: normal;
    font-weight: 600;
    transition: color .3s ease;
    color: var(--cl-teal);
    cursor: pointer;

    &:hover {
      color: var(--cl-clementine);
    }

    &:not(:last-child) {
      + .teams-hub-section__item-link.with-icon {
        margin-top: ac(6px, 4px);
      }
    }

    &.with-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      --size: 25px;
      width: 25px;
      height: 25px;
      background: var(--cl-teal);
      transition: background-color .3s ease;

      i {
        position: relative;
        z-index: 1;
        color: var(--cl-white);
        transition: color .3s ease;
      }

      &:hover {
        background-color: var(--cl-clementine);
      }
    }
  }

  &__item-text {
    width: 74.40%;

    @media (max-width: 801px) {
      width: 55%;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__item-open-content {
    grid-column: 1 / -1;
    position: relative;

    &:after {
      position: absolute;
      z-index: 1;
      height: 1px;
      background: var(--cl-border-color);
      content: "";
      transform: translateX(-50%);
      left: 50%;
      width: 100vw;
      bottom: 0;
    }

    &.active {
      margin-top: ac(-9px, 0px);
      .teams-hub-section {
        &__item-container {
          max-height: 1000px;
          opacity: 1;
          padding-bottom: ac(50px, 24px);
        }
      }
    }

    /*&:not(.active) {
      .teams-hub-section {
        &__item-container {
          max-height: 0 !important;
          opacity: 0 !important;
        }
      }
    }*/

    .teams-hub-section {
      &__item-container {
        display: flex !important;
        transition: max-height 1s ease, opacity .3s ease;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        padding-bottom: 1px;
      }
    }
  }

  &__load {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    font-family: var(--font-second);
    font-size: ac(20px, 18px);
    font-weight: 600;
    line-height: 1.6;
    z-index: 5;

    &:before {
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      height: 1px;
      background: var(--cl-black);
      transition: background-color 0.4s ease, height 0.2s ease;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      &:before {
        transition: background-color 0.3s ease, height 0.3s ease;
        height: 50%;
        background-color: var(--cl-mint);
      }
    }
  }
}
