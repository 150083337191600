.contact-section {
  padding-top: 0;

  padding-bottom: ac(40px, 20px);
  position: relative;
  z-index: 10;

  &__color-bg {
    background: var(--cl-black);
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 851px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__left-container {
    padding-top: ac(50px, 30px);
    padding-bottom: ac(50px, 30px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 44.04%;
    padding-right: ac(47px, 20px);
    clip-path: inset(0 -1px -100% -100%);
    position: relative;

    &:before {
      position: absolute;
      z-index: 1;
      width: 1px;
      background: #313030;
      content: "";
      right: 0;
      height: 200%;
      top: 0;
    }

    @media (max-width: 1024px) {
      width: 36%;
    }

    @media (max-width: 851px) {
      width: 100%;
      clip-path: none;

      &:before {
        content: none;
      }
    }
  }

  &__title {
    width: 100%;
    padding-bottom: ac(50px, 30px);

    &:after {
      position: absolute;
      z-index: 1;
      height: 1px;
      background: #313030;
      content: "";
      transform: translateX(-50%);
      left: 50%;
      width: 100vw;
      bottom: 0;
    }

    h2 {
      font-size: ac(40px, 28px);
      font-weight: 500;
      line-height: 125%;

      .word:first-child {
        margin-left: ac(-14px, -5px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    .swiper-wrapper {

      @media (min-width: 852px) {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;

        .filters-slider__item {
          width: 100% !important;

          &:not(:last-child) {
            margin-bottom: ac(30px, 20px) !important;
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    .filters-slider {
      &__item {
        &.active {
          .filters-slider__item-btn {
            border-top-color: var(--cl-primrose);
            color: var(--cl-primrose);
            opacity: 1;
            font-weight: 600;
            font-size: ac(30px, 18px);
          }
        }
      }

      &__item-btn {
        border-top-color: var(--cl-beige);
        color: var(--cl-beige);
        font-size: ac(25px, 16px);
        line-height: 1.4;
        font-weight: 400;
        opacity: 1;
        padding-top: 12px;
        transition: all .25s linear;
      }
    }
  }

  &__container {
    margin-top: ac(80px, 30px);
    margin-bottom: ac(50px, 30px);
    width: 48.79%;
    position: relative;

    @media (max-width: 1100px) {
      width: 52%;
    }

    @media (max-width: 1024px) {
      width: 60%;
    }

    @media (max-width: 851px) {
      margin-top: 0;
      width: 100%;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.3s ease;
    pointer-events: none;

    &.active {
      position: relative;
      left: auto;
      opacity: 1;
      pointer-events: all;
    }

    @mixin media 1250 {
      .meta-upload-file {
        label,
        .label {
          padding-bottom: 8px;
        }

        &__info {
          position: relative;
          margin-top: 5px;
          right: auto;
          top: auto;
        }
      }
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(30px, 16px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(33px, 24px);
    }

    @mixin media 551 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__form-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .meta-checkbox-list__item:not(:last-child) {
      margin-bottom: ac(11px, 8px);
    }

    &:not(:last-child) {
      margin-bottom: ac(34px, 24px);
    }
  }

  &__form-bottom {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .meta-checkbox {
      align-items: flex-start;

      &__box {
        margin-top: 7px;
      }

      &__text {
        line-height: 171.43%;
      }
    }


  }

  &__form-bottom-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: ac(40px, 0px);
    grid-column-gap: ac(22px, 16px);
    grid-row-gap: 20px;

    .meta-checkbox__text {
      text-align: right;
    }

    .btn {
      &__text {
        white-space: nowrap;
      }
    }
    
    @media (max-width: 541px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .meta-checkbox__text {
        text-align: left;
      }
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }

    &.first-big-paragraph {
      p {
        &:first-child {
          font-family: var(--font-main);
          font-weight: 400;
          font-size: ac(30px, 24px);
          line-height: 133.33%;

          &:not(:last-child) {
            margin-bottom: ac(31px, 24px);
          }
        }
      }
    }
  }
}
