.footer {
  position: relative;
  padding-top: 0;
  padding-bottom: ac(52px, 40px);

  &__color-bg {
    background: var(--cl-beige);
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &__content {
    width: 100%;
    border-top: 1px solid var(--cl-leaf);
    border-bottom: 1px solid var(--cl-leaf);
    padding-top: 30px;
    padding-bottom: ac(35px, 24px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: ac(47px, 26px);

    @mixin media 390 {
      @media (min-width: 361px) {
        max-width: 284px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__content-top,
  &__content-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 40px;
    /*flex-wrap: wrap;*/

    @mixin media 1020 {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__content-top {
    grid-row-gap: 28px;
  }

  &__content-bottom {
    grid-row-gap: 32px;
    flex-wrap: wrap;
  }

  &__logo {
    @mixin aspect-ratio 105, 25;
    width: 105px;

    img {
      object-fit: contain;
    }
  }

  &__contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-column-gap: ac(40px, 12px);
    grid-row-gap: ac(16px, 14px);
    flex-wrap: wrap;

    @mixin media 1200 {
      grid-column-gap: 12px;
    }

    @mixin media 1020 {
      justify-content: center;
    }
  }

  &__contact-link {
    position: relative;
    font-family: var(--font-second);
    font-size: ac(16px, 14px);
    font-weight: 400;
    line-height: 1.62;
    transition: color 0.3s ease;

    &:before {
      right: 0;
      left: auto;
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--cl-teal);
      opacity: 0;
      transition: width 0.3s ease, opacity 0.3s ease;
      content: '';
      position: absolute;
    }

    &:hover {
      color: var(--cl-teal);

      &:before {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
      }
    }

    @media (max-width: 639px) {
      text-align: center;
      font-size: 16px;
      line-height: 1.57;

      &:before {
        content: none;
      }
    }
  }

  &__socials {
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(30px, 22px);
    grid-row-gap: 16px;
    flex-wrap: wrap;

    @mixin media 1200 {
      grid-column-gap: 18px;
    }

    @mixin media 1100 {
      width: 100%;
      grid-column-gap: ac(30px, 22px);
    }

    @mixin media 1020 {
      justify-content: center;
    }

    @media (max-width: 639px) {
      grid-row-gap: 16px;
    }

    @mixin media 390 {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__nav-link {
    position: relative;
    z-index: 1;
    font-size: ac(15px, 14px);
    line-height: 25px;
    font-weight: 600;
    transition: color 0.3s ease;

    @media (max-width: 639px) {
      font-size: 16px;

      i {
        display: none;
      }
    }

    i {
      position: absolute;
      right: 0;
      opacity: 0;
      top: 55%;
      transform: translateY(-50%);
      transition: opacity 0.1s ease, transform 0.3s ease, color 0.3s ease;
      font-size: 12px;
      z-index: -1;
      color: var(--cl-black);
    }

    &:hover {
      color: var(--cl-teal);

      i {
        transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
        transform: translateX(calc(100% + 3px)) translateY(-50%);
        opacity: 1;
        color: var(--cl-teal);
      }
    }
  }

  &__bottom {
    padding-top: ac(19px, 24px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    grid-row-gap: 12px;
    grid-column-gap: ac(20px, 16px);

    @mixin media 901 {
      justify-content: center;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__bottom-info {
    font-family: var(--font-second);
    font-size: ac(14px, 16px);
    font-weight: 400;
    line-height: 24px;

    @mixin media 901 {
      width: 100%;
      text-align: center;
    }
  }

  &__bottom-link {
    font-family: var(--font-second);
    position: relative;
    font-size: ac(14px, 16px);
    font-weight: 400;
    line-height: 24px;
    /*transition: color .3s ease;*/
    z-index: 1;

    &:before {
      right: 0;
      left: 0;
      bottom: 0;
      height: 1px;
      background: var(--cl-teal);
      transition: height 0.3s ease, opacity 0.3s ease, background-color .3s ease;
      content: '';
      position: absolute;
      z-index: -1;
    }

    &:hover {
      &:before {
        background-color: var(--cl-primrose);
        height: 50%;
      }
    }
  }

  &__developer {
    width: 150px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @mixin media 901 {
      margin-top: 10px;
      margin-left: 200px;
      margin-right: 200px;
    }

    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 639px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}
