.sectors-section {
  padding-top: ac(100px, 50px);
  padding-bottom: ac(40px, 30px);
  z-index: 10;
  overflow: hidden;

  &.brand-type {
    padding-top: ac(40px, 20px);
    padding-bottom: ac(40px, 20px);
    .sectors-section {
      &__wrap {
        padding-top: ac(110px, 40px);
        padding-bottom: ac(110px, 40px);
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 0;
    }

    &.pure-page {
      .sectors-section__item-link {
        background-color: var(--cl-royal);

        &:hover {
          background-color: var(--cl-powder);
        }

        .link-btn {
          color: var(--cl-beige);

          &:before {
            background-color: var(--cl-beige);
          }

          &__icon {
            color: var(--cl-beige);
          }
        }
      }
    }

    &.catalyst-page {
      .sectors-section {
        &__color-bg {
          background-color: transparent;
        }

        &__wrap {
          padding-top: 0;
          padding-bottom: 0;
        }

        &__item-link {
          background-color: var(--cl-tangerine);

          &:hover {
            background-color: var(--cl-sunflower);
          }
        }
      }

      @media (max-width: 639px) {
        padding-bottom: 40px !important;
      }
    }
  }

  &__color-bg {
    background-color: var(--cl-beige);
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__title {
    h2 {
      br {
        + span {
          margin-left: ac(54px, 24px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__text {
    width: 100%;
    max-width: ac(1034px, 640px);

    &.first-big-paragraph {
      p:first-child {
        @media (min-width: 1280px) {
          padding-right: 10px;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 30px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    &:not(.swiper-initialized) {
      overflow: visible;
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: ac(30px, 20px);
        grid-row-gap: ac(40px, 24px);

        @mixin media 1200 {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @mixin media 690 {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      .slider-buttons {
        display: none;
      }

      &.catalyst-type {
        .swiper-wrapper {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));

          @mixin media 901 {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            .sectors-section__item {
              &:nth-child(3):last-child {
                grid-column: 1/ -1;
              }
            }
          }
        }
      }
    }

    &.swiper-initialized {
      overflow: visible;

      .slider-buttons {
        .slider-btn {
          margin-top: 30px;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      .sectors-section__item-title {
        background-color: rgba(28, 28, 28, 0.9);
      }

      .sectors-section__item-content {
        background-color: rgba(28, 28, 28, 0.9);
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 108px;
    background: var(--cl-black);
    transition: background-color .3s ease;
    padding: ac(29px, 24px) ac(27px, 18px) ac(116px, 85px);
    height: 100%;

    .sectors-section {
      &__item-title {
        padding: 0;
        background: transparent !important;
        min-height: ac(80px, 48px);
        align-items: flex-start;
        
        &:not(:last-child) {
          margin-bottom: ac(55px, 30px);
        }

        h3 {
          font-size: ac(30px, 18px);
          line-height: 133.33%;
        }
      }
      
      &__item-text {
        width: 100%;
      }

      &__item-link {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
        min-height: ac(84px, 57px);
        min-width: ac(175px, 120px);
      }
    }
  }

  &__item-title {
    width: 100%;
    padding: ac(20px, 10px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 108px;
    background: var(--cl-black);
    transition: background-color .3s ease;

    h3 {
      font-size: ac(23px, 18px);
      line-height: 141.67%;
      font-weight: 600;
      color: var(--cl-beige) !important;
      letter-spacing: -0.01em;
      
      @media (max-width: 1400px) {
        font-size: ac(20px, 18px);
      }
    }
  }

  &__item-icon {
    @mixin aspect-ratio 1, 1;
    width: ac(57px, 54px);
    background: var(--cl-mint);
    margin-bottom: auto;

    img {
      z-index: 5;
    }
  }

  &__item-link {
    min-height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-mint);
    transition: all .3s ease;
    padding: 4px 20px 6px;
    margin-left: auto;

    &:hover {
      background-color: var(--cl-leaf);

      .link-btn__icon i {
        transform: translateY(ac(2px, 1px)) translateX(0) !important;
      }
    }
  }
}
