.team-card {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__container {
    width: 100%;
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: ac(20px, 18px);
    }
  }

  &__image {
    @mixin aspect-ratio 288, 341;
    width: 100%;

    img {
      transition: transform 0.3s ease;
    }
  }

  &__item {
    position: absolute;
    z-index: 2;
    width: auto;
    max-width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-mint);
    padding: ac(36px, 24px) ac(50px, 20px);
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.35s ease;
  }

  &__title {
    font-family: var(--font-main);
    font-size: ac(30px, 22px);
    line-height: 1.33;
    font-weight: 600;
    @mixin max-line-length 4;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__subtitle {
    font-family: var(--font-main);
    font-size: ac(24px, 18px);
    line-height: 130%;
    font-weight: 500;
    @mixin max-line-length 4;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    li {
      border-top: 1px solid var(--cl-black);
      padding-top: ac(9px, 8px);
      padding-bottom: ac(9px, 8px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      font-family: var(--font-main);

      &:last-child {
        border-bottom: 1px solid var(--cl-black);
      }

      a {
        font: inherit;
        transition: color 0.3s ease;

        &:hover {
          color: var(--cl-leaf);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  &__item-social {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    transition: color 0.3s ease;

    &:hover {
      color: var(--cl-leaf);
    }
  }

  &:hover {
    .team-card {
      &__image {
        img {
          transform: scale(1.05);
        }
      }

      &__item {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}
