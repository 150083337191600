.our-purpose-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  overflow: hidden;
  position: relative;
  z-index: 20;

  &__color-bg {
    background: var(--cl-deep-teal);
    overflow: hidden;
  }

  &__wrap {
    padding-top: ac(110px, 30px);
    padding-bottom: ac(110px, 30px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: ac(80px, 30px);
    }

    .our-purpose-section {
      &__text {
        &:first-child {
          width: 40.74%;
        }

        &:last-child {
          width: 57.33%;
        }

        &.bigger-size {
          span {
            color: var(--cl-primrose) !important;
          }
        }
      }
    }
  }

  &__second-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    --px: ac(47px, 20px);
    position: relative;

    &:after, &:before {
      position: absolute;
      z-index: 1;
      height: 1px;
      background: #486A5D;
      content: "";
      transform: translateX(-50%);
      left: 50%;
      width: 100vw;
    }

    &:after {
      top: 0;
    }

    &:before {
      bottom: 0;
    }

    .our-purpose-section {
      &__text {
        padding-top: ac(50px, 20px);
        padding-bottom: ac(50px, 30px);

        &.bigger-size {
          span {
            color: var(--cl-clementine) !important;
          }
        }

        &:first-child {
          width: 52.58%;
          padding-right: var(--px);
          border-right: 1px solid #486A5D;
        }

        &:last-child {
          width: 47.42%;
          padding-left: var(--px);
          margin-left: -1px;
          border-left: 1px solid #486A5D;
        }
      }
    }
  }

  &__text {
    &.bigger-size {
      font-weight: 600;

      p {
        font-weight: 600;
        line-height: 1.25;
      }
    }
  }

  @media (max-width: 901px) {
    .our-purpose-section {
      &__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;

        .our-purpose-section {
          &__text {

            &:first-child {
              width: 100%;
            }

            &:last-child {
              width: 100%;
            }
          }
        }
      }

      &__second-content {
        padding-bottom: 30px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .our-purpose-section {
          &__text {
            padding-bottom: 0;
            &:first-child {
              width: 100%;
              padding-right: 0;
              border-right: none;
            }

            &:last-child {
              padding-top: 0;
              width: 100%;
              padding-left: 0;
              border-left: none;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 639px) {
    padding-bottom: 0;
  }
}