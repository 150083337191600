.work-section {
  padding-top: ac(40px, 20px);
  padding-bottom: ac(40px, 20px);
  overflow: hidden;
  z-index: 25;

  &__color-bg {
    background: #CBE2B8;
    position: relative;
    overflow: hidden;
  }

  &__bg-decor {
    position: absolute;
    inset: 0 -1px 0 0;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right;
    }

    @media (max-width: 640px) {
      top: 120px;
    }
  }

  &__top-decor {
    @mixin aspect-ratio 588.57, 147.19;
    position: absolute;
    top: 0;
    right: 0;
    width: ac(588px, 120px);
  }

  &__wrap {
    position: relative;
    z-index: 2;
    padding-top: ac(110px, 50px);
    padding-bottom: ac(110px, 30px);
  }

  &__title {
    h2 {
      font-family: var(--font-third) !important;

      .word {
        &:first-child {
          &:before {
            display: none !important;
          }

          margin-left: 0 !important;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(50px, 24px);
    }
  }

  &__slider {
    overflow: visible;

    &:not(.swiper-initialized) {
      display: none;
    }
  }

  &__slider-buttons {
    .slider-btn {
      margin-top: 28px;
    }
  }

  &__item {
    height: auto;
    padding: ac(27px, 24px) ac(27px, 20px) ac(27px, 24px) ac(30px, 20px);
    background: #058668;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-title {
    border-top: 1px solid #CBE2B8;
    padding-top: ac(19px, 16px);
    width: 100%;
    font-size: ac(34px, 22px);
    line-height: 1.18;
    font-weight: 600;
    font-family: var(--font-third);
    color: #CBE2B8;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__item-text {
    color: #CBE2B8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    &.active {
      .work-section__item-hidden {
        padding-top: ac(10px, 8px);
        opacity: 1;
        max-height: 1000px;
      }
    }

    .work-section__item-hidden {
      padding-top: 0;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s ease, opacity .35s ease, padding-top .3s ease;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    li {
      color: #CBE2B8;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    ul {
      li {
        margin-bottom: 0 !important;
        &:before {
          background-color: #CBE2B8;
        }
      }
    }

  }

  &__item-links {
    margin-top: auto;

    .link-btn {
      &:before {
        background-color: #CBE2B8;
        transition: all .3s ease;
      }

      .link-btn__text, .link-btn__icon {
        color: #CBE2B8;
        transition: all .3s ease;
      }

      .link-btn__text {
        font-family: var(--font-third) !important;
      }

      .link-btn__icon {
        font-size: 12px;
        height: 12px;
        margin-top: 2px;

        i {
          transform: none;
        }
      }

      &:hover {
        &:before {
          background-color: var(--cl-beige);
        }
        .link-btn__text, .link-btn__icon {
          color: var(--cl-beige);
        }
      }
    }
  }

  &.student-type {
    .work-section {
      &__color-bg {
        background: #023532;
      }

      &__slider-buttons {
        .slider-btn {
          &.slider-btn-second {
            color: var(--cl-beige);

            &:hover {
              &:not(.swiper-button-disabled) {
                color: #CBE2B8;
              }
            }
          }
        }
      }

      &__title {
        h2 {
          color: var(--cl-white) !important;
        }
      }
      
      &__text {
        margin-top: ac(-20px, 0px);
        color: var(--cl-white) !important;

        p, li {
          color: var(--cl-white) !important;
        }

        &:not(:last-child) {
          margin-bottom: ac(50px, 30px);
        }

        &.bigger-size {
          font-weight: 600;
          letter-spacing: 0;

          p, li {
            font-weight: 600;
            letter-spacing: 0;
          }
        }
      }

      &__item {
        background: #CBE2B8;
        border: 1px solid #058668;
      }

      &__item-title {
        border-top: 1px solid #023532;
        color: #023532;

        &:not(:last-child) {
          margin-bottom: ac(20px, 16px);
        }
      }

      &__item-text {
        color: #023532;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        li {
          color: #023532;
        }

        ul {
          li {
            &:before {
              background-color: #023532;
            }
          }
        }
      }

      &__item-links {
        margin-top: auto;

        .link-btn {
          &:before {
            background-color: #023532;
          }

          .link-btn__text, .link-btn__icon {
            color: #023532;
          }

          &:hover {
            &:before {
              background-color: var(--cl-clementine);
            }
            .link-btn__text, .link-btn__icon {
              color: var(--cl-clementine);
            }
          }
        }
      }
    }

    &.dark-bg {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      li {
        color: var(--cl-white);
      }

      p,
      li {
        a {
          &:hover {
            color: var(--cl-primrose);
          }
        }
      }
    }

    @media (max-width: 639px) {
      padding-bottom: 0;
    }
  }
}